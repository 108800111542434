import { BaseModel } from '@stoom-infra/biblioteca-stoom';

class RolesModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.name = data.name;
    this.description = data.description;
    this.hierarchy = data.hierarchy;
    this.authorityGroups = data.authorityGroups || [];
    this.canManageSameHierarchyAdministrators = data.canManageSameHierarchyAdministrators;
    this.canManageNonDescendantChildAdministrators = data.canManageNonDescendantChildAdministrators;
  }
}

export default RolesModel;

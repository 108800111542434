import { extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import BranchModel from "~/models/BranchModel";
import BranchesAPI from "../services/BranchesAPI";
import FileModel from "~/models/FileModel";
import UploadAPI from "../services/UploadAPI";
import WithdrawlsModel from "~/models/WithdrawlsModel";
class BranchesStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();

    extendObservable(this, {
      branchesList: [],
      branchesListSelect: undefined,
      branch: undefined,
      withdrawals: [],
      branchFile: undefined,
      totalPages: 0,
      size: 15,
      page: 0,
      sort: "created,desc",
      filters: {},
      loading: false,
    });
  }

  reset() {
    this.branchesList = [];
    this.branchesListSelect = [];
    this.branch = undefined;
    this.totalPages = 0;
    this.size = 15;
    this.page = 0;
    this.sort = "created,desc";
    this.loading = false;
  }

  initializeBranch() {
    const merchant = this.rootStore.usersStore.userMerchant;
    this.branch = new BranchModel({ merchant });
  }

  async getBranchesListSelect() {
    const response = await BranchesAPI.getList({
      size: 100,
      page: 0,
      sort: "name",
    });

    if (response.error) {
      this.branchesListSelect = [];
      return;
    }

    this.branchesListSelect = response.content.map((branch) => ({
      value: branch.uuid,
      label: branch.name,
    }));
  }

  async getList(
    size = 15,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;
    const response = await BranchesAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });
    this.loading = false;

    if (response.error) {
      this.branchesList = [];
      return;
    }

    this.branchesList = response.content.map(
      (branch) => new BranchModel(branch)
    );
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.size = response.size;
  }

  async setPage(page) {
    await this.getList(this.size, page);
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, sort);
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getList(this.size, this.page, this.sort, filters);
  }

  getFileData() {
    return new FileModel({
      file: this.branchFile[0],
      metaTags: ["branch"],
      name: this.branchFile[0].name,
    });
  }

  async save() {
    this.loading = true;

    const merchant = this.rootStore.usersStore.userMerchant;

    this.branch.merchant = { uuid: merchant?.uuid };

    const response = await BranchesAPI.save({
      ...this.branch,
      // merchant
    });
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return response;
    }

    if (this.branchFile && this.branchFile.length) {
      await UploadAPI.uploadBranch(response.uuid, this.getFileData());
    }

    this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Salvo com sucesso!");
    return response;
  }

  async update() {
    this.loading = true;
    const response = await BranchesAPI.update(this.branch.uuid, {
      ...this.branch,
      // merchant
    });
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return response;
    }

    if (this.branchFile && this.branchFile.length) {
      await UploadAPI.uploadBranch(response.uuid, this.getFileData());
    }

    this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Atualizado com sucesso!");
    return response;
  }

  async get(uuid) {
    this.loading = true;
    const response = await BranchesAPI.get(uuid);
    this.loading = false;

    this.branch = new BranchModel({
      ...response,
      "@class": "br.com.stoom.partage.model.PartageBranch",
    });
  }

  async delete(uuid) {
    this.loading = true;
    const response = await BranchesAPI.delete(uuid);
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Deletado com sucesso!");
    }

    return response;
  }

  setHoursProp(prop, value) {
    const key = prop.split("_")[0];
    const isOpen = prop.includes("start");

    const oldHour = this.branch[key]
      ? this.branch[key].split("as")[isOpen ? 1 : 0].replace(/\s/g, "")
      : "";
    const newHour = `${isOpen ? value : oldHour} as ${
      isOpen ? oldHour : value
    }`;

    if (key === "sundayOpeningHours") this.branch.holidayOpeningHours = newHour;

    this.branch[key] = newHour;
  }

  async getExpoxtList(
    size = 1000,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    const response = await BranchesAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content.map((value) => new BranchModel(value));
  }

  onChange(prop, value) {
    switch (prop) {
      case "file":
        this.branchFile = value;
        break;
      case "state":
        this.branch.state = value.value;
        break;
      case "openingHours_start":
      case "openingHours_end":
      case "saturdayOpeningHours_start":
      case "saturdayOpeningHours_end":
      case "sundayOpeningHours_start":
      case "sundayOpeningHours_end":
        this.setHoursProp(prop, value);
        break;
      case "telphone":
        this.branch["phone"] = value;
        break;
      case "lat":
        this.branch["lat"] = parseFloat(value);
        break;
      case "lng":
        this.branch["lng"] = parseFloat(value);
        break;
      default:
        this.branch[prop] = value;
    }
    this.branch = new BranchModel(this.branch)
  }

  async getWithdrawals(uuid) {
    this.loading = true;
    const response = await BranchesAPI.getWithdrawals(uuid);
    this.loading = false;

    if (!response.error) {
      this.withdrawals = response.map(
        (withdrawal) => new WithdrawlsModel(withdrawal)
      );
    } else {
      this.withdrawals = [];
    }
  }

  async createWithdrawals(data, uuid) {
    this.loading = true;
    const response = await BranchesAPI.createWithdrawals(uuid, [data]);
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return response;
    }

    return response;
  }

  async deleteWithdrawal(uuid) {
    this.loading = true;
    const response = await BranchesAPI.deleteWithdrawal(uuid);
    this.loading = false;

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Deletado com sucesso!");
    }

    return response;
  }
}

export default BranchesStore;

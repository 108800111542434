const BannersPermissionEnum = Object.freeze({
  PUT_INACTIVE: {
    pattern: "/manager/banners/**/inactive",
    action: "PUT",
  },
  VIEW_BANNERS: {
    pattern: "/manager/banners/**",
    action: "GET",
  },
  GET: {
    pattern: "/manager/banners/search",
    action: "GET",
  },
  POST: {
    pattern: "/manager/banners",
    action: "POST",
  },
  PUT_ACTIVE: {
    pattern: "/manager/banners/**/inactive",
    action: "PUT",
  },
  PUT: {
    pattern: "/manager/banners/**",
    action: "PUT",
  },
  DELETE: {
    pattern: "/manager/banners/**",
    action: "DELETE",
  },
  DELETE_IMAGE: {
    pattern: "/manager/banners/**/image/**",
    action: "DELETE",
  },
  GET_UUID: {
    pattern: "/manager/banners/**",
    action: "GET",
  },
});

export default BannersPermissionEnum;

import BaseAPI, { URLS } from "./BaseAPI";

class ChannelAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(`${URLS.CHANNELS}/search`, params);
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar a lista de canais" };
    } catch (e) {
      return { error: "Falha ao carregar a lista de canais" };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.CHANNELS, data);
      return response.status === 201
        ? response.data
        : { error: "Falha ao salvar canal" };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : "Falha ao salvar canal",
      };
    }
  }

  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.CHANNELS}/${uuid}`, data);
      return response.status === 200
        ? response.data
        : { error: "Falha ao atualizar canal" };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : "Falha ao salvar canal",
      };
    }
  }

  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.CHANNELS}/${uuid}`);
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar canal" };
    } catch (e) {
      return { error: "Falha ao carregar canal" };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.CHANNELS}/${uuid}`);
      return response.status === 204
        ? response.data
        : { error: "Falha ao deletar canal" };
    } catch (e) {
      return { error: "Falha ao deletar canal" };
    }
  }
}

export default ChannelAPI;

import React from "react";
import ReactLoading from "react-loading";

/**
 * @Name: ColComponent
 * @Data: 2019
 * @Desc: Div com classe bootstrap que agrupa diversos componentes em uma coluna
 * @param  {string} className="col"
 * @param  {string} size - (lg, md ou sm)
 * @param  {string} cols - ( 0 a 12)
 * @param  {any} children
 * @param  {any} style
 **/

const LoadingAllScreenComponent = (props) => {
  const { text } = props;

  // Recebe as propriedades
  return (
    <div className="overlay-all">
      <ReactLoading
        type={"bubbles"}
        color={"#016d79"}
        height={"70px"}
        width={"70px"}
      />
      {text && <p>{text}</p>}
    </div>
  );
};

export default LoadingAllScreenComponent;

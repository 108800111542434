import BaseModel from "./BaseModel";
import MerchantModel from "./MerchantModel";

export default class BannerModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.merchant = data.merchant && new MerchantModel(data.merchant);
    this.uuid = data.uuid;
    this.nameTag = data.nameTag;
    this.filesAmount = data.filesAmount;
    this.tag = data.tag;
    this.responsive = data.responsive;
    this.files = data.files || [];
  }
}

const NewsletterPermissionEnum = Object.freeze({
  POST: {
    pattern: '/manager/newsletters',
    action: 'POST',
  },
  PUT: {
    pattern: '/manager/newsletters/**',
    action: 'PUT',
  },
  DELETE: {
    pattern: '/manager/newsletters/**',
    action: 'DELETE',
  },
  GET: {
    pattern: '/manager/newsletters/search',
    action: 'GET',
  },
  GET_UUID: {
    pattern: '/manager/newsletters/**',
    action: 'GET',
  },
});

export default NewsletterPermissionEnum;

import { extendObservable } from "mobx";
import ColorAPI from "../services/ColorAPI";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import ColorModel from "~/models/ColorModel";

class ColorStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      color: undefined,
      colors: [],
      colorsListSelect: [],
      staffs: [],
      loading: false,
      superAdmin: false,
      totalPages: 0,
      size: 10,
      page: 0,
      sort: "name",
    });
  }

  reset() {
    this.color = undefined;
  }

  /**Atualiza uma propriedade do novo adminstrator*/
  async onChange(prop, value) {
    const _color = this.color ? this.color : new ColorModel();
    switch (prop) {
      default:
        _color[prop] = value;
    }
    this.color = new ColorModel(_color);
  }

  /**Cria um novo usuário adminin */
  async onCreate() {
    this.loading = true;
    const data = JSON.stringify(this.color);
    const response = await ColorAPI.save(data);
    this.loading = false;
    if (!response.error) {
      this.color = new ColorModel();
      this.toastHelper.notify(STATUS_HELPER.INFO, "Cor criada com sucesso.");
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 10, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = "name";

    sort ? (this.sort = sort) : (this.sort = "name");

    await this.getList();
    return true;
  }

  /**Busca todos os usuarios addmins */
  async getList(size = 10, page = this.page, sort = this.sort) {
    this.loading = true;
    const response = await ColorAPI.getList({
      size,
      page,
      sort,
    });
    this.loading = false;
    if (response.error) return [];
    this.colors = response.content.map((usr) => new ColorModel(usr));
    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  /**
   * Busca todas as informações de um usuario administrador.
   * @param  {string} userName
   */
  async get(userName) {
    this.loading = true;
    const response = await ColorAPI.get(userName);
    this.loading = false;
    if (!response.error) {
      this.color = new ColorModel(response);
      return this.color;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, this.sort);
  }

  /**Busca um usuário customer por um parametro. */
  async onUpdate() {
    this.loading = true;

    const data = JSON.stringify(this.color);
    const response = await ColorAPI.update(this.color.uuid, data);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Informações atualizadas com sucesso!"
      );
      this.color = new ColorModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Deleta administrador */
  async delete(uuid) {
    this.loading = true;
    const response = await ColorAPI.delete(uuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.INFO, "Cor excluída com sucesso!");
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async getColorListSelect() {
    const response = await ColorAPI.getList({
      size: 100,
      page: 0,
      sort: "name",
    });

    if (response.error) {
      this.colorsListSelect = [];
      return;
    }

    this.colorsListSelect = response.content.map((color) => ({
      value: color.uuid,
      label: color.name,
    }));
  }

  async getExpoxtList(
    size = 1000,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    const response = await ColorAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content.map((usr) => new ColorModel(usr));
  }
}
export default ColorStore;

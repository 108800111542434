import AuthoritiesModel from "./AuthoritiesModel";
import BaseModel from "./BaseModel";

/**Modelo usuário */
class UserModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.username = data.username;
    this.email = data.email;
    this.password = data.password;
    this.role = data.role;
    this.authorities = data.authorities
      ? data.authorities.map((value) => new AuthoritiesModel(value))
      : [];
    this.authorityGroups = data.authorityGroups
      ? data.authorityGroups.map((value) => new AuthoritiesModel(value))
      : [];
    this.uuid = data.uuid;
    this.idUser = data.idUser;
  }
}

export default UserModel;

// const CampaignModelsEnum = Object.freeze({
//   X_OFF_Y_UNIDADE: 'X% Off na Y unidade',
//   DESCONTO_PERCENTUAL: 'Desconto Percentual X',
//   COMPRE_W_UNIDADES_DE_X_GANHE_Y_NO_PRODUTO_Z:
//     'Compre W Unidades de X e ganhe Y% de desconto no produto Z',
//   DESCONTO_PROGRESSIVO:
//     'Desconto Progressivo - X% de Desconto na compra acima de Y unidades',
//   VALOR_BASE_COM_X_OFF: 'Atingimento de valor base com desconto X%',
//   FRETE_GRATIS: 'Frete Gratis',
//   LEVE_X_PAGUE_Y: 'Leve X Pague Y',
//   Fixed_Price_Campaign: 'Frete Fixo',
//   Progressive_Discount: 'Desconto Progressivo',
//   FREE_SHIPPING: 'Frete Grátis',
// });

const CampaignModelsEnum = Object.freeze({
  DESCONTO_PERCENTUAL: "Desconto Percentual X",
  FRETE_FIXO: "Frete Fixo",
  DESCONTO_ABSOLUTO: "Desconto Absoluto X",
  MODELO_CAMPANHA_LEVE_X_PAGUE_Y: "Leve X Pague Y",
});

export default CampaignModelsEnum;

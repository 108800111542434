import UserModel from './UserModel';
import MerchantModel from './MerchantModel';
import BaseModel from './BaseModel';
import RolesModel from './RolesModel'

class AccountModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.idAdministrator = data.idAdministrator;
    this.name = data.name;
    this.user = new UserModel(data.user);
    this.merchant = new MerchantModel(data.merchant);
    this.role = new RolesModel(data.role);
  }
}

export default AccountModel;

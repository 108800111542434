import { extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import SalesAPI from "~/services/SalesAPI";

const initValues = {
  loading: false,
  abandonedCarts: {},
  listAbandonedCarts: [],
  reportsCart: {},
  defaultTo: "",
  defaultFrom: "",
  filters: {},
  size: 15,
  page: 0,
  totalPages: 0,
};

class AbandonedCartsStore {
  totalPages = 0;
  page = 0;
  size = 15;
  sort = "modified,desc";
  filter = "";
  totalelements = 0;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, { ...initValues });
  }

  async setFilters(filters) {
    this.filters = filters;
    this.page = 0;
    await this.getAbandonedCarts(filters);
    await this.getReportsCard(filters);
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getAbandonedCarts();
  }

  async setPage(numPage, size = 15, isPending = false, sort) {
    this.page = numPage;
    this.size = size;
    await this.getAbandonedCarts(this.filters);
    await this.getReportsCard(this.filters);
    return true;
  }

  /**Consulta carrinhos abandonados*/
  async getAbandonedCarts(filters) {
    this.loading = true;
    const params = {
      from: this.filters.from
        ? this.filters?.from + "T00:00:00.556"
        : this.defaultFrom + "T00:00:00.556",
      to: this.filters.to
        ? this.filters?.to + "T23:59:59"
        : this.defaultTo + "T23:59:59",
      customer: this.filters?.customer,
      sort: this.sort,
    };
    const size = this.size;
    const page = this.page;
    const response = await SalesAPI.getAbandonedCarts(params, size, page);

    if (!response.error) {
      this.totalelements = response.totalElements;
      this.totalPages = response.totalPages;
      this.page = response.number;
      this.listAbandonedCarts = response;
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
  }

  async getFirstFilterDates() {
    const fDate = new Date();
    const tDate = new Date();

    fDate.setDate(fDate.getDate() - 30);
    const fromYear = fDate.getFullYear();
    const fromMonth =
      fDate.getMonth() + 1 >= 10
        ? fDate.getMonth() + 1
        : `0${fDate.getMonth() + 1}`;
    const fromDay =
      fDate.getDate() >= 10 ? fDate.getDate() : `0${fDate.getDate()}`;
    const fromDate = `${fromYear}-${fromMonth}-${fromDay}`;

    const toYear = tDate.getFullYear();
    const toMonth =
      tDate.getMonth() + 1 >= 10
        ? tDate.getMonth() + 1
        : `0${tDate.getMonth() + 1}`;
    const toDay =
      tDate.getDate() >= 10 ? tDate.getDate() : `0${tDate.getDate()}`;
    const toDate = `${toYear}-${toMonth}-${toDay}`;
    this.defaultTo = toDate;
    this.defaultFrom = fromDate;
  }

  /**Consulta carrinhos abandonados*/
  async getReportsCard(filters) {
    this.loading = true;
    const params = {
      from: this.filters.from
        ? this.filters?.from + "T00:00:00.556"
        : this.defaultFrom + "T00:00:00.556",
      to: this.filters.to
        ? this.filters?.to + "T23:59:59"
        : this.defaultTo + "T23:59:59",
      customer: this.filters?.customer,
    };

    const response = await SalesAPI.getReportsCard(params);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    else {
      this.reportsCart = response;
    }
    this.loading = false;
  }

  async getExpoxtList() {
    const params = {
      from: this.filters.from
        ? this.filters?.from + "T00:00:00.556"
        : this.defaultFrom + "T00:00:00.556",
      to: this.filters.to
        ? this.filters?.to + "T23:59:59"
        : this.defaultTo + "T23:59:59",
      customer: this.filters?.customer,
    };

    const size = 99999;
    const page = this.page;

    const response = await SalesAPI.getAbandonedCarts(params, size, page);
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content;
  }
}

export default AbandonedCartsStore;

import { extendObservable } from "mobx";
import InstitutionalModel from "../models/InstitutionalModel";
import InstitutionalAPI from "../services/InstitutionalAPI";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";

class InstitutionalStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      unit: undefined,
      loading: false,
      imageToAdd: undefined,
      institutional: new InstitutionalModel(),
      institutionals: [],
      totalPages: 0,
      pageStyle: undefined,
      page: 0,
      size: 15,
    });
  }

  reset() {
    this.unit = [];
    this.loading = false;
    this.institutional = new InstitutionalModel();
  }

  async getList(size = this.size, page = this.page, sort = this.sort) {
    this.loading = true;
    const response = await InstitutionalAPI.getList({
      size,
      page,
      sort,
    });
    if (response.error) return [];
    this.institutionals = response.result.content.map(
      (usr) => new InstitutionalModel(usr)
    );
    this.totalPages = response.result.totalPages;
    this.page = response.result.number;
    this.loading = false;
  }

  async get(uuid) {
    this.loading = true;
    this.disabledItem = false;
    const response = await InstitutionalAPI.get(uuid);
    if (!response.error) {
      this.institutional = new InstitutionalModel(response.result);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  onChange(prop, value) {
    switch (prop) {
      case "pageType":
        this.institutional[prop] = value.value;
        this.pageStyle = value.value;
        break;
      case "topic":
        this.institutional[prop] = value.value;
        break;
      case "name":
        this.institutional[prop] = value;
        break;
      case "ordination":
        this.institutional[prop] = value;
        break;
      case "externalLink":
        this.institutional[prop] = value;
        break;
      default:
    }
  }

  async handleSaveContent(value) {
    if (!this.institutional.content.filter((e) => e.title === value.title)) {
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        "Já existe titulo com o mesmo nome!"
      );
      return false;
    } else {
      this.institutional.content.push(value);
      this.institutional = new InstitutionalModel(this.institutional);

      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Adicionbado com sucesso!"
      );
      return true;
    }
  }

  async handleDeleteContent(title) {
    const removeItem = this.institutional.content.filter(
      (item) => item.title !== title
    );

    this.institutional.content = removeItem;
    this.institutional = new InstitutionalModel(this.institutional);
    this.toastHelper.notify(
      STATUS_HELPER.SUCCESS,
      "Item removido com sucesso!"
    );
  }

  descriptionChange(prop, value) {
    this.institutional[prop] = value;
  }

  async onCreate(myJson) {
    this.loading = true;
    if (
      this.institutional.pageType !== "FREE_TEXT" &&
      this.institutional.pageType !== "EDITOR_HTML"
    ) {
      this.institutional.content = JSON.stringify(myJson);
    }
    delete this.institutional.type;
    this.institutional.type = "FOOTER_2";

    const data = this.institutional;

    const response = await InstitutionalAPI.save(data);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Informações atualizadas com sucesso!"
      );
      this.institutional.content = [];
      this.institutional = new InstitutionalModel({});
      this.institutionals.push(new InstitutionalModel(response));
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  async onUpdate(myJson) {
    this.loading = true;
    if (
      this.institutional.pageType !== "FREE_TEXT" &&
      this.institutional.pageType !== "EDITOR_HTML"
    ) {
      this.institutional.content = JSON.stringify(myJson);
    }
    delete this.institutional.type;
    this.institutional.type = "FOOTER_2";
    const data = this.institutional;

    const response = await InstitutionalAPI.update(
      this.institutional.uuid,
      data
    );
    this.loading = false;
    if (response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        "Erro ao editar institucional"
      );
    } else {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Dados atualizados");
    }
    return response;
  }

  async delete(uuid) {
    this.loading = true;
    const response = await InstitutionalAPI.delete(uuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Excluído com sucesso!");
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, this.sort);
  }

  async setPage(page) {
    await this.getList(this.size, page);
  }

  async getExpoxtList(
    size = 1000,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    const response = await InstitutionalAPI.getList({
      size,
      page,
      sort,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.result.content.map((usr) => new InstitutionalModel(usr));
  }
}
export default InstitutionalStore;

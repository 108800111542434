import BaseModel from "./BaseModel";
import CategoryModel from "./CategoryModel";
class DynamicHomeModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.active = data.active ? data.active : true;
    this.app = data.app ? data.app : false;
    this.minDate = data.minDate ? data.minDate : new Date("01-01-2000");
    this.maxDate = data.maxDate ? data.maxDate : new Date("01-01-2050");
    this.uuid = data.uuid;
    this.banners = data.banners;
    this.category = data.category && new CategoryModel(data.category);
    this.mobile = data.mobile;
    this.name = data.name;
    this.order = data.order;
    this.showcase = data.showcase;
    this.type = data.type;
    this.branch = data.branch;
  }
}

export default DynamicHomeModel;

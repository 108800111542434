
import BaseModel from './BaseModel';

class StampModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.name = data.name !== undefined ? data.name  : '';
    this.script = data.script !== undefined ? data.script : '';
    this.link = data.link !== undefined ? data.link : '';
    this.ordination = data.ordination !== undefined ? data.ordination : '';
  }
}

export default StampModel;
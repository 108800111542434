const CategoriesPermissionEnum = Object.freeze({
  POST: {
    pattern: "/manager/categories",
    action: "POST",
  },
  PUT: {
    pattern: "/manager/categories/**",
    action: "PUT",
  },
  DELETE: {
    pattern: "/manager/categories/**",
    action: "DELETE",
  },
  GET: {
    pattern: "/manager/categories/tree",
    action: "GET",
  },
  GET_UUID: {
    pattern: "/manager/categories/**",
    action: "GET",
  },
  FILES: {
    pattern: "/manager/files/categories/**",
    action: "POST",
  },
});

export default CategoriesPermissionEnum;

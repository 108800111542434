import { extendObservable } from "mobx";
import { v4 as uuidv4 } from 'uuid';
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import CampaignModel from "~/models/CampaignModel";
import CampaignAPI from "~/services/CampaignAPI";
import CustomerAPI from "../services/CustomerAPI";
import { isArrayEmpty, onlyUnique } from "~/helpers/utils/Functions";
import CampaignModelsEnum from "~/helpers/utils/enums/CampaignModelsEnum";
import {
  DateRestriction,
  PriceRestriction,
  ProductCategoryRestriction,
  CustomerGroupRestriction,
  CustomerRestriction,
  PointOfSaleRestriction,
  ZipCodeRestriction,
} from "~/models/RestrictionModel";
import {
  converterStringToNumber,
  generateTreeUuid,
} from "../helpers/utils/Functions";
import DiscountModel from "../models/Discount";
import PromotionApplicationTypeEnum from "~/helpers/utils/enums/PromotionApplicationTypeEnum";

export const campaignItems = [
  {
    label: CampaignModelsEnum.DESCONTO_PERCENTUAL,
    value: CampaignModelsEnum.DESCONTO_PERCENTUAL,
  },
  {
    label: CampaignModelsEnum.DESCONTO_ABSOLUTO,
    value: CampaignModelsEnum.DESCONTO_ABSOLUTO,
  },
  // {
  //   label: CampaignModelsEnum.FRETE_FIXO,
  //   value: CampaignModelsEnum.FRETE_FIXO,
  // },
];

const initValues = {
  loading: false,
  totalPages: 0,
  page: 0,
  size: 15,
  typeCampaign: "",
  defaultTo: "",
  defaultFrom: "",
  campaingReports: [],
  defaultCategory: "",
  buyXPayY: {},
  isAllProducts: false,
  campaign: new CampaignModel({ active: false }),
  infiniteCampaign: false,
  clients: false,
  priceRestriction: new PriceRestriction(),
  dateRestriction: new DateRestriction(),
  productCategoryRestriction: new ProductCategoryRestriction(),
  pointOfSaleRestriction: new PointOfSaleRestriction(),
  customerRestriction: new CustomerRestriction(),
  customerGroupRestriction: new CustomerGroupRestriction(),
  zipCodeRestriction: new ZipCodeRestriction(),
  zipCodeRestrictions: [],
  discount: new DiscountModel(),
  campaigns: [],
  documents: [],
  campaignItems: campaignItems,
  zipCodeList: [],
};

class CampaignStore {
  totalPages = 0;
  page = 0;
  size = 15;
  sort = "created,desc";

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, { ...initValues });
  }

  async setPage(numPage, size = 15, useLogin = true, sort) {
    this.page = numPage;
    this.size = size;
    sort && (this.sort = sort);
    await this.getList();
    return this.totalPages;
  }

  reset() {
    this.campaign = new CampaignModel();
    this.dateRestriction = new DateRestriction();
    this.campaigns = [];
    this.totalPages = 0;
    this.size = 15;
    this.page = 0;
    this.productCategoryRestriction = new ProductCategoryRestriction();
    this.loading = true;
    this.applicationType = undefined;
    this.typeCampaign = "";
    this.documents = [];
    this.discount = new DiscountModel();
    this.shippingRange = false;
    this.zipCodeRestrictions = new ZipCodeRestriction();
    this.zipCodeList = [];
  }

  /**Busca lista de campanhas */
  async getList(
    size = 15,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;

    const response = await CampaignAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    this.loading = false;
    this.campaigns = response.content;
    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  async getExpoxtList(
    size = 1000,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    const response = await CampaignAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content.map((usr) => new CampaignModel(usr));
  }

  async getCustomer(filters) {
    const response = await CustomerAPI.getList({
      size: 15,
      page: 0,
      ...filters,
    });

    if (!response.error) {
      if (response.content && response.content.length > 0) {
        return response.content[0];
      }
      return [];
    }
    return [];
  }

  handleSearchDocument = (documents) => {
    documents.map(async (item) => {
      let data;

      let search = "";
      if (item !== "" && item !== undefined) {
        search += `,document;${item}`;
      }

      data = await this.getCustomer({ search });
      this.documents.push({
        uuid: data.uuid,
        firstName: data.firstName,
        active: data.active,
        document: data.document,
      });
    });
    this.loading = false;
  };

  /**Função para ativar/inativar campanha no grid */
  async activeCampaign(campaign) {
    const response = await CampaignAPI.switchActiveFlag(campaign.uuid);

    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        `Campanha ${campaign.active ? "ativada" : "inativada"} com sucesso`
      );
      this.getList();
      return true;
    }
    this.toastHelper.notify(
      STATUS_HELPER.ERROR,
      `Erro ao ${campaign.active ? "ativar" : "inativar"} a campanha`
    );
    return false;
  }

  setValueShippingRange(value) {
    if (this.zipCodeRestrictions === undefined) {
      this.zipCodeRestrictions = new ZipCodeRestriction();
    }

    if (value != undefined) {
      this.shippingRange = value;
      this.zipCodeRestrictions.excludes = value;
    }
  }
  clearDocument() {
    this.documents = [];
  }
  async getCampaignByUuid(campaignUuid) {
    this.loading = true;

    const response = await CampaignAPI.getCampaignByUuid(campaignUuid);
    if (!response.error) {
      this.campaign = new CampaignModel(response);
      if (!!response?.discounts[0]?.buyXPayY?.x) {
        this.buyXPayY.x = response.discounts[0].buyXPayY.x;
        this.buyXPayY.y = response.discounts[0].buyXPayY.y;
      }
      this.typeCampaign = response?.type;

      if (response?.restrictions && response?.restrictions?.length > 0) {
        this.organizeRestrictions(response?.restrictions);
        let zipCodeTemp = [];

        response.restrictions?.map((restriction) => {
          if (restriction["@class"].includes("ZipCodeRestriction")) {
            zipCodeTemp.push(restriction);
          }
        });

        this.zipCodeList = zipCodeTemp[0]?.zipCodeRangeRestrictions;

        if (response.restrictions.length > 0) {
          const categoryFind = response.restrictions.find(
            (item) =>
              item["@class"] ===
              "br.com.stoom.kernel.model.persistence.restriction.ProductCategoryRestriction"
          );

          if (categoryFind !== undefined) {
            this.defaultCategory = generateTreeUuid(categoryFind?.categories);
          }
        }

        this.zipCodeRestrictions = zipCodeTemp[0];
        const productsRestriction = response.restrictions?.map((restriction) =>
          restriction["@class"].includes("ProductCategoryRestriction")
        );
        if (productsRestriction && productsRestriction?.products?.length > 0) {
          this.productCategoryRestriction.products = [
            ...productsRestriction.products,
          ];
        }
      }
      // this.loading = false;
      return this.campaign;
    }
    this.loading = false;
    return false;
  }

  /**Função para entrar nas restricoes e direcionar para o objetos */
  async organizeRestrictions(restrictions) {
    restrictions.map((r) => {
      switch (r["@class"]) {
        case "br.com.stoom.kernel.model.persistence.restriction.ProductCategoryRestriction":
          this.productCategoryRestriction = r;
          break;
        case "br.com.stoom.kernel.model.persistence.restriction.DateRestriction":
          if (r.maxDate.includes("2121")) {
            this.infiniteCampaign = true;
            this.dateRestriction.minDate = r.minDate;
            this.dateRestriction.maxDate = undefined;
          } else {
            this.infiniteCampaign = false;
            this.dateRestriction.minDate = r.minDate;
            this.dateRestriction.maxDate = r.maxDate;
          }
          break;
        case "br.com.stoom.kernel.model.persistence.restriction.PriceRestriction":
          this.priceRestriction = r;
          break;
        case "br.com.stoom.kernel.model.persistence.restriction.GroupRestriction":
          this.customerGroupRestriction = r;
          break;
        case "br.com.stoom.kernel.model.persistence.restriction.CustomerRestriction":
          this.clients = r.excludes;

          if (r.documents.length > 0) {
            this.handleSearchDocument(r.documents);
          }

          break;
        case "br.com.stoom.kernel.model.persistence.restriction.ZipCodeRestriction":
          this.shippingRange = r?.excludes;
          break;
        default:
          break;
      }
    });
  }

  selectedModelCampaign(modelSelected) {
    this.productCategoryRestriction = new ProductCategoryRestriction();
    this.typeCampaign = modelSelected;
    this.isAllProducts = false;
    this.handleCampaignChange("type", modelSelected);
  }

  checkedAllProducts() {
    this.isAllProducts = true;
    this.productCategoryRestriction.categories = [];
    this.productCategoryRestriction.products = undefined;
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getList(this.size, this.page, this.sort, filters);
  }

  setValueClients(value) {
    if (value !== undefined) {
      const customer = this.customerRestriction;
      customer.excludes = value;
      this.customerRestriction = customer;
    }
  }

  handleCampaignChange(prop, value) {
    switch (prop) {
      case "excludes":
        this.productCategoryRestriction[prop] = value;
        break;
      default:
    }

    const { campaign } = this;
    campaign[prop] = value;
    this.campaign = new CampaignModel(campaign);
  }

  checkedInfiniteCampaign(checked) {
    this.infiniteCampaign = checked;
    if (checked) {
      this.dateRestriction.maxDate = undefined;
    }
  }

  handleChangeDate(prop, value) {
    if (prop === "maxDate") {
      this.dateRestriction.maxDate = value;
    } else {
      this.dateRestriction.minDate = value;
    }
  }

  /**Altera as propriedades de desconto */
  handleChangeDiscount(prop, value) {
    if (typeof value !== "number") value = value.replace(",", ".");
    this.campaign.discounts[0][prop] = Number(value) / 100;

    if (prop === "productsAbsoluteDiscount") {
      this.campaign.bean = "Discount_Campaign";
      this.campaign.type = "Desconto Absoluto X";
    }

    if (prop === "productsPercentageDiscount") {
      this.campaign.bean = "Discount_Campaign";
      this.campaign.type = "Desconto Percentual X";
    }

    // this.campaign = new CampaignModel(updatedCampaign);
  }

  /**Funcao utilizada para alterar valor da restricao buyXProductRestriction*/
  changeBuyXPayYRestriction(field, value) {
    this.buyXPayY[field] = value;

    this.campaign.bean = "Buy_X_Pay_Y_Campaign";
    this.campaign.type = "Leve X Pague Y";

    this.campaign.discounts[0] = new DiscountModel({
      buyXPayY: this.buyXPayY,
    });
  }

  handleChangeDiscountAbsolute(prop, value) {
    this.campaign.discounts[0][prop] = converterStringToNumber(value);

    if (prop === "productsAbsoluteDiscount") {
      this.campaign.bean = "Discount_Campaign";
      this.campaign.type = "Desconto Absoluto X";
    }

    if (prop === "productsPercentageDiscount") {
      this.campaign.bean = "Discount_Campaign";
      this.campaign.type = "Desconto Percentual X";
    }
    // this.campaign = new CampaignModel(updatedCampaign);
  }

  /**Salva uma nova campanha */
  async onSave() {
    this.loading = true;

    this.campaign.restrictions = this.getRestritions();

    const response = await CampaignAPI.save(this.campaign);
    this.loading = false;

    if (!response.error) {
      this.campaign = response.data;
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Campanha adicionada com sucesso!"
      );
      this.reset();
      return true;
    }

    this.toastHelper.notify(
      STATUS_HELPER.ERROR,
      "Erro ao adicionar a campanha"
    );
    return false;
  }

  getRestritions() {
    if (this.infiniteCampaign) {
      this.dateRestriction.maxDate = "2121-05-03T00:00:00.000Z";
    }

    const restrictions = [...this.setRestrictionsInBody(this.campaign.type)];

    if (
      this.productCategoryRestriction.categories.length >= 1 ||
      this.productCategoryRestriction.products.length >= 1
    ) {
      restrictions.push(this.productCategoryRestriction);
    }

    if (this.zipCodeRestrictions !== undefined) {
      const newData = this.zipCodeRestrictions.zipCodeRangeRestrictions.map(
        (item) => {
          if (item.uuid.startsWith('created ')) {
            return { ...item, uuid: item.uuid.replace('created ', '') };
          } else {
            return item;
          }
        }
      );
      this.zipCodeRestrictions.zipCodeRangeRestrictions = newData;

      const allDeleted =
        this.zipCodeRestrictions.zipCodeRangeRestrictions.every(
          (restriction) => restriction.deleted === true
        );

      if (allDeleted === true) {
        this.zipCodeRestrictions.deleted = true;
      } else {
        this.zipCodeRestrictions.deleted = false;
      }

      restrictions.push(this.zipCodeRestrictions);
    }

    if (this.customerGroupRestriction.customerGroups.length > 0) {
      restrictions.push(this.customerGroupRestriction);
    }

    if (this.documents.length > 0) {
      let arrayDocuments = [];
      this.documents.map((item) => {
        arrayDocuments.push(item.document);
      });
      this.customerRestriction.documents.push(...arrayDocuments);
      restrictions.push(this.customerRestriction);
    }

    return restrictions;
  }

  /**Funcao para atualizar a campanha */
  async onUpdate() {
    this.loading = true;
    const { campaign } = this;

    if (this.infiniteCampaign) {
      this.dateRestriction.maxDate = "2121-05-03T00:00:00.000Z";
    }

    campaign.restrictions = this.getRestritions();
    const response = await CampaignAPI.update(campaign, campaign.uuid);
    this.loading = false;

    if (!response.error) {
      this.reset();

      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Campanha atualizada com sucesso!"
      );

      return true;
    }

    this.toastHelper.notify(
      STATUS_HELPER.ERROR,
      "Erro ao atualizar a campanha"
    );
    return false;
  }

  /**Seta as restricoes de acordo com a campanha para usar no post/put */
  setRestrictionsInBody(type) {
    switch (type) {
      case CampaignModelsEnum.DESCONTO_PERCENTUAL:
        if (this.isAllProducts) {
          return [this.dateRestriction];
        } else {
          if (
            this.productCategoryRestriction.categories.length >= 1 ||
            this.productCategoryRestriction.products.length >= 1
          ) {
            return [this.dateRestriction, this.productCategoryRestriction];
          }
          return [this.dateRestriction];
        }

      default:
        return [this.dateRestriction];
    }
  }

  async handleAddZipCode(data) {
    if (this.zipCodeRestrictions === undefined) {
      this.zipCodeRestrictions = new ZipCodeRestriction();
    }

    if (this.zipCodeList === undefined) {
      this.zipCodeList = [];
    }

    const addItem = {
      '@class': 'br.com.stoom.kernel.model.dto.ZipCodeRangeRestrictions',
      endZipCode: data.finalZipCode,
      startZipCode: data.initialZipCode,
      deleted: false,
      uuid: 'created' + ' ' + uuidv4(),
    };

    this.zipCodeList.push(addItem);
    this.zipCodeRestrictions.zipCodeRangeRestrictions = this.zipCodeList;
  }

  handleDeleteZipCode(data) {
    if (data.uuid.includes('created')) {
      const filter = this.zipCodeRestrictions.zipCodeRangeRestrictions.filter(
        (item) => item.uuid !== data.uuid
      );
      this.zipCodeList = filter;
      this.zipCodeRestrictions.zipCodeRangeRestrictions = filter;
    } else {
      const newData = this.zipCodeRestrictions.zipCodeRangeRestrictions.map(
        (item) => {
          if (item.uuid === data.uuid) {
            return { ...item, deleted: true };
          } else {
            return item;
          }
        }
      );
      this.zipCodeRestrictions.zipCodeRangeRestrictions = newData;
      this.zipCodeList = newData;
    }
  }

  /**Adiciona o produto na lista de restrição */
  async handleAddGroup(data) {
    if (this.customerGroupRestriction.customerGroups === undefined) {
      this.customerGroupRestriction.customerGroups = [];
    }

    if (data !== undefined) {
      this.customerGroupRestriction.customerGroups.push(data);
    }
  }

  handleDeleteGroup(data) {
    const filter = this.customerGroupRestriction.customerGroups.filter(
      (item) => item.value !== data.value
    );
    this.customerGroupRestriction.customerGroups = filter;
  }



  applicationTypeVerify() {
    let applicationType = false;

    if (
      this.productCategoryRestriction.categories &&
      this?.productCategoryRestriction?.categories?.length > 0
    ) {
      applicationType = {
        label: "Categoria",
        value: PromotionApplicationTypeEnum.CATEGORIA,
      };
    } else if (
      this.productCategoryRestriction.products &&
      this.productCategoryRestriction.products.length > 0
    ) {
      applicationType = {
        label: "Produto",
        value: PromotionApplicationTypeEnum.PRODUTO,
      };
    }

    return applicationType;
  }

  setCategory(categories) {
    const selected = categories.map((category) => {
      return {
        uuid: category.uuid,
        categoryName: category.categoryName,
        "@class": category["@class"],
      };
    });

    this.categoriesRemove = this.productCategoryRestriction.categories.filter(
      (cat) => !selected.some((mCat) => mCat.uuid === cat.uuid)
    );

    this.defaultCategory = generateTreeUuid(categories);

    this.categoriesAdd = selected.filter(
      (cat) =>
        !this.productCategoryRestriction.categories.some(
          (mCat) => mCat.uuid === cat.uuid
        )
    );

    this.productCategoryRestriction.categories = [
      ...this.productCategoryRestriction.categories,
      ...this.categoriesAdd,
    ]
      .filter((f) => this.categoriesRemove.indexOf(f) < 0)
      .filter(onlyUnique);
    this.productCategoryRestriction.products = [];

    return [
      ...this.productCategoryRestriction.categories,
      ...this.categoriesAdd,
    ]
      .filter((f) => this.categoriesRemove.indexOf(f) < 0)
      .filter(onlyUnique);
  }

  removeCategory() {
    this.productCategoryRestriction.categories = [];
    this.productCategoryRestriction.products = [];
    this.defaultCategory = generateTreeUuid([]);
  }

  async deleteCampaign(campaignUuid) {
    const response = await CampaignAPI.delete(campaignUuid);

    if (!response.error) {
      await this.getList();
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Campanha deletada com sucesso"
      );
      return true;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, "Falha ao deletar a campanha");
  }

  addProduct(product) {
    if (isArrayEmpty(this.productCategoryRestriction.products)) {
      this.productCategoryRestriction.products = [];
    }

    this.productCategoryRestriction.products.push(product);
    this.productCategoryRestriction.categories = [];
  }

  async addDocument(data) {
    const documentExists = this.documents.some(
      (item) => item.document === data.document
    );

    if (!documentExists) {
      this.documents.push(data);
    }
  }

  removeProduct(product) {
    if (isArrayEmpty(this.productCategoryRestriction.products)) {
      return;
    }

    this.productCategoryRestriction.products =
      this.productCategoryRestriction.products.filter(
        (f) => f.uuid !== product.uuid
      );
    this.productCategoryRestriction.categories = [];
    // this.renderAttribute = !this.renderAttribute;
  }

  removeDocument(item) {
    const docIndex = this.documents.findIndex(
      (value) => value.uuid === item.uuid
    );

    const filter = this.documents.filter((_, index) => index !== docIndex);

    this.documents = filter;
  }

  //Reports
  async getFirstFilterDates() {
    const fDate = new Date();
    const tDate = new Date();

    fDate.setDate(fDate.getDate() - 30);
    const fromYear = fDate.getFullYear();
    const fromMonth =
      fDate.getMonth() + 1 >= 10
        ? fDate.getMonth() + 1
        : `0${fDate.getMonth() + 1}`;
    const fromDay =
      fDate.getDate() >= 10 ? fDate.getDate() : `0${fDate.getDate()}`;
    const fromDate = `${fromYear}-${fromMonth}-${fromDay}`;

    const toYear = tDate.getFullYear();
    const toMonth =
      tDate.getMonth() + 1 >= 10
        ? tDate.getMonth() + 1
        : `0${tDate.getMonth() + 1}`;
    const toDay =
      tDate.getDate() >= 10 ? tDate.getDate() : `0${tDate.getDate()}`;
    const toDate = `${toYear}-${toMonth}-${toDay}`;

    this.defaultTo = toDate;
    this.defaultFrom = fromDate;
  }

  async setFiltersCampaingReports(filters) {
    this.filters = filters;
    await this.getCampaingReports(this.filters);
  }

  async setSortCampaingReports(sort) {
    this.sort = sort;
    await this.getCampaingReports();
  }

  async getCampaingReports() {
    this.loading = true;

    const params = {
      from: this.filters?.from
        ? this.filters?.from + "T00:00:00"
        : this.defaultFrom + "T00:00:00",
      to: this.filters?.to
        ? this.filters?.to + "T00:00:00"
        : this.defaultTo + "T23:59:59",
      customerEmail: this.filters?.customerEmail
        ? this.filters?.customerEmail
        : undefined,
      finalZipCode: this.filters?.finalZipCode,
      initialZipCode: this.filters?.initialZipCode,
      status: this.filters?.status?.value,
      categoryUuid: this.filters?.categoryUuid?.value,
      productUuid: this.filters?.productUuid
        ? this.filters?.productUuid
        : undefined,
      customerDocument: this.filters?.customerDocument
        ? this.filters?.customerDocument
        : undefined,
    };

    const size = this.size;
    const page = 0;

    const response = await CampaignAPI.getCampaingReports(params, size, page);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    else {
      this.campaingReports = response;
      this.totalPages = response.totalPages;
      this.page = response.number;
    }
    this.loading = false;
  }

  setValueDiscount(type, value) {
    const discount = this.discount;
    if (type === "productsAbsoluteDiscount") {
      discount.productsAbsoluteDiscount = parseFloat(value);
      discount.productsPercentageDiscount = 0;
    } else if (type === "productsPercentageDiscount") {
      discount.productsPercentageDiscount = value.length > 0 ? value / 100 : 0;
      discount.productsAbsoluteDiscount = 0;
    }
    this.discount = discount;
  }

  async setPageReports(numPage, size = 15) {
    this.page = numPage;
    this.size = size;
    await this.getCupomReports();
    return true;
  }

  async getExportReporttList() {
    const params = {
      from: this.filters?.from
        ? this.filters?.from + "T00:00:00"
        : this.defaultFrom + "T00:00:00",
      to: this.filters?.to
        ? this.filters?.to + "T00:00:00"
        : this.defaultTo + "T23:59:59",
      customerEmail: this.filters?.customerEmail
        ? this.filters?.customerEmail
        : undefined,
      finalZipCode: this.filters?.finalZipCode,
      initialZipCode: this.filters?.initialZipCode,
      status: this.filters?.status?.value,
      categoryUuid: this.filters?.categoryUuid?.value,
      productUuid: this.filters?.productUuid
        ? this.filters?.productUuid
        : undefined,
      customerDocument: this.filters?.customerDocument
        ? this.filters?.customerDocument
        : undefined,
    };

    const size = 99999;

    const response = await CampaignAPI.getCampaingReports(params, size, 0);
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response;
  }
}

export default CampaignStore;

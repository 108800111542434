class DiscountModel {
  constructor(data = {}) {
    this["@class"] = "br.com.stoom.kernel.model.persistence.Discount";
    this.uuid = data.uuid;
    this.deleted = data.deleted;
    this.productsAbsoluteDiscount = data.productsAbsoluteDiscount || 0;
    this.productsPercentageDiscount = data.productsPercentageDiscount || 0;
    this.shippingAbsoluteDiscount = data.shippingAbsoluteDiscount || 0;
    this.shippingPercentageDiscount = data.shippingPercentageDiscount || 0;
    this.buyXPayY = {
      x: data.buyXPayY && data.buyXPayY.x ? data.buyXPayY.x : 0,
      y: data.buyXPayY && data.buyXPayY.y ? data.buyXPayY.y : 0,
      cumulative: true,
    };
  }
}

export default DiscountModel;

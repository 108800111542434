import { extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import { ProductTypeEnum } from "~/helpers/utils/Enums";
import ProductComentModel from "~/models/ProductComentModel";
import ProductAPI from "../services/ProductAPI";

/**Valores inicias de variaveis observadas */
const initValues = {
  loading: false,
  products: [],
  valueState: "",
  statuses: "ACCEPTED,PENDING,REJECTED",
  defaultTo: "",
  defaultFrom: "",
  reportRatingList: [],
  size: 15,
  page: 0,
  totalPages: 0,
};
class ProductCommentStore {
  totalPages = 0;
  page = 0;
  size = 20;
  sort = "rating,desc";
  filter = "";
  loading = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.rootStore.customerTypeStore.getList();
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      ...initValues,
    });
  }

  reset() {
    this.totalPages = 0;
    this.page = 0;
    this.products = [];
    this.size = 20;
    this.sort = "rating,desc";
    this.filter = "";
    this.loading = false;
  }

  /**Retorna instancia da notificationStore  */
  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  handleCommentRating = async (value, el, stateSelected) => {
    this.loading = true;
    const status = value;
    const response = await ProductAPI.updateStatusComment(el, el.uuid, value);
    if (!response.error) {
      const comment = this.products.find((comment) => comment.uuid === el.uuid);
      comment.status = status;
      this.getList({ statuses: stateSelected });
      this.products = [...this.products];
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  };

  async getList(data) {
    this.loading = true;
    this.statuses = data?.statuses ?? this.statuses;

    const params = {
      page: this.page,
      size: this.size,
      sort: this.sort,
      name: this.filter,
      statuses: this.statuses,
      ...data,
    };

    if (params.rating === 0) {
      delete params.rating;
    }

    const response = await ProductAPI.getRatings(params);
    if (!response.error) {
      this.products = await response.content.map(
        (prd) => new ProductComentModel(prd)
      );

      this.totalPages = response.totalPages;
      this.page = response.number;
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response);
    }
    this.loading = false;
  }

  async setPage(numPage) {
    this.page = numPage;
    this.getList({ page: this.page });
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList();
  }

  async getExpoxtList(
    size = 1000,
    page = this.page,
    sort = this.sort,
    filters = this.filters,
    statuses = this.statuses
  ) {
    const response = await ProductAPI.getRatings({
      size,
      page,
      sort,
      statuses,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content.map((value) => new ProductComentModel(value));
  }
}

export default ProductCommentStore;

import BaseAPI, { URLS } from "./BaseAPI";

class TemplateEmailAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(
        `${URLS.TEMPLATE_EMAIL}/search`,
        params
      );
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar lista de templates" };
    } catch (e) {
      return { error: "Falha ao carregar lista de templates" };
    }
  }

  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.TEMPLATE_EMAIL}/${uuid}`);
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar" };
    } catch (e) {
      return { error: "Falha ao carregar" };
    }
  }

  static async getDataJson(type) {
    try {
      const response = await BaseAPI.get(
        `${URLS.TEMPLATE_EMAIL}/search?search=example:true,type:${type}`
      );
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar" };
    } catch (e) {
      return { error: "Falha ao carregar" };
    }
  }

  static async sendEmailTesting(data) {
    try {
      const response = await BaseAPI.put(`${URLS.TEMPLATE_EMAIL}/test`, data);

      return response.status === 200 && response.data.message === "email sent!"
        ? response.data
        : { error: response.data.message };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : "Falha ao enviar E-mail",
      };
    }
  }

  static async updateTemplate(data, uuid) {
    try {
      const response = await BaseAPI.put(
        `${URLS.TEMPLATE_EMAIL}/${uuid}`,
        data
      );
      return response.status === 200
        ? response.data
        : { error: "Falha ao salvar Email" };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : "Falha ao salvar Email",
      };
    }
  }
}

export default TemplateEmailAPI;

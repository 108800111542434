import { extendObservable } from 'mobx';
import StampModel from '../models/StampModel';
import StampAPI from '../services/StampAPI';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import FileModel from '~/models/FileModel';
import UploadAPI from '../services/UploadAPI';

class StampStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      unit: undefined,
      loading: false,
      imageToAdd: undefined,
      stamp: new StampModel(),
      stamps: [],
      totalPages: 0,
      page: 0,
      size: 10,
    });
  }

  reset() {
    this.unit = [];
    this.loading = false;
  }

  async getList(size = this.size, page = this.page, sort = this.sort, ) {
    this.loading = true;
    const response = await StampAPI.getList(
      {
        size,
        page,
        sort,
      }
    );
    if (response.error) return [];
    this.stamps = response.map((usr) => new StampModel(usr));
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.loading = false;

  }

  /**Atualiza um novo registror*/
  async onChange(prop, value) {
    const _storeSetup = this.stamp ? this.stamp : new StampModel();
    switch (prop) {
      default:
        _storeSetup[prop] = value;
    }
    this.stamp = _storeSetup;
  }

  handleUploadImage(file) {
    this.loading = true
    const newFileItem = new FileModel({
      file,
      ...file,
      metaTags: ['selo'],
      size: 1,
      path: file?.preview,
      uuid: Math.random() * 5
    });
    this.imageToAdd = newFileItem

    this.loading = false
    this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Imagem cadastrada com sucesso!');

  }

  async sendFiles() {
    this.imageToAdd &&
      await UploadAPI.uploadImageHeaderStoreSetup(this.imageToAdd.file[0]);
  }

  async onCreate() {
    this.loading = true;

    const data = JSON.stringify(this.stamp);
    const response = await StampAPI.update(data);
    this.stamp = new StampModel()
    if (!response.error) {
      this.sendFiles()
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Informações atualizadas com sucesso!'
      );
      this.stamps.push(new StampModel(response))

    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  async delete(uuid) {
    this.loading = true;
    const response = await StampAPI.delete(uuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Excluído com sucesso!');
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }
}
export default StampStore;

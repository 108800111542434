import BaseAPI, { URLS } from "./BaseAPI";

class ShippingServiceAPI {
  /**Método util para retorno de erro */
  static _exception(e, message) {
    return { error: message };
  }

  /**
   * @param  {string} uuid - Uuid da serviço de entrega à ser procurada
   */
  static async getService(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.SHIPPING}/${uuid}`, {
        by: "uuid",
      });
      if (response.status === 200) return response.data;
      const error =
        response.status === 404
          ? "Serviço de entrega não encontrado"
          : "Erro inexperado ao buscar Serviço de entrega";
      return { error };
    } catch (e) {
      return this._exception(e, "Erro ao buscar serviço de entrega");
    }
  }

  /**
   * @param  {string} uuid - Uuid da serviço de entrega à ser deletada
   */
  static async deleteService(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.SHIPPING}/${uuid}`);
      if (response.status === 204) return response.data;
      const error =
        response.status === 404
          ? "Serviço de entrega não encontrado"
          : "Erro inexperado ao buscar Serviço de entrega";
      return { error };
    } catch (e) {
      return this._exception(e, "Erro ao deletar Serviço de entrega");
    }
  }

  /**
   * @param  {string} uuid - Uuid da serviço de entrega à ser atualizada
   */
  static async updateService(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.SHIPPING}/${uuid}`, data);
      if (response.status === 200) return response.data;
      const error =
        response.status === 404
          ? "Serviço de entrega não encontrado"
          : "Erro inexperado ao atualizar Serviço de entrega";
      return { error };
    } catch (e) {
      return this._exception(e, "Erro ao atualizar dados da indústria");
    }
  }

  /**
   * @param  {object} data - dados do novo shipping que será salvo
   */
  static async saveService(data) {
    try {
      const response = await BaseAPI.post(`${URLS.SHIPPING}`, data);
      if (response.status === 201) return response;
      return { error: "Erro inexperado ao cadastrar serviço de entrega" };
    } catch (e) {
      return this._exception(
        e,
        e?.response?.data?.message ?? "Falha ao cadastrar serviço de entrega"
      );
    }
  }

  /**Busca lista de suppliers */
  static async getListServices(params) {
    try {
      const response = await BaseAPI.get(`${URLS.SHIPPING}`, params);
      if (response.status === 200) return response.data;
      return { error: "Erro inexperado ao buscar lista de serviço de entrega" };
    } catch (e) {
      return this._exception(e, "Falha ao buscar lista de serviço de entrega");
    }
  }
}

export default ShippingServiceAPI;

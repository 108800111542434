import React from "react";
import { Provider } from "mobx-react";
import { ToastContainer, Slide } from "react-toastify";
//Estilos
import "../../libs/imports/bootstrap";
import "../../libs/imports/jquery";
import { initializeStores } from "~/stores/RootStore";
//Rotas
import Routes from "../../routes";
import MainContainer from "../../containers/MainContainer";
import '../../helpers/validators/validator';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Aqui você pode registrar o erro ou realizar outras ações necessárias
    console.error(error, errorInfo);
  }

  refreshPage() {
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="boundary-class">
          <h1>Ooops, algo deu errado!</h1>
          <p>
            Pedimos desculpas pelo inconveniente. Estamos trabalhando nisso e
            resolveremos o problema o mais rápido possível.
          </p>

          <div className="update-screen">
            <p onClick={this.refreshPage}>Atualizar página</p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

const App = () => {
  const mobxStore = initializeStores().mobxStores;

  return (
    <div className="App">
      <ErrorBoundary>
        <Provider {...mobxStore}>
          <section className="MainSection">
            <MainContainer role="main">
              <Routes />
            </MainContainer>
            <ToastContainer transition={Slide} />
          </section>
        </Provider>
      </ErrorBoundary>
    </div>
  );
};

export default App;

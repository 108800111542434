import AccountModel from './AccountModel';
import UserModel from './UserModel';

class AdministratorModel extends AccountModel {
  constructor(data = {}) {
    super(data);
    //this['@class'] = "br.com.stoom.kernel.model.persistence.Administrator";
    this.idAdministrator = data.idAdministrator;
    this.modified = data.modified;
    this.name = data.name;
    this.email = data.email;
    this.pass = data.pass;
    this.confirmEmail = data.confirmEmail;
    this.username = data.username;
    this.active = data.active;
    this.uuid = data.uuid;
    this.action = data.action;
    this.authorities = data.authorities;
    this.user = new UserModel(data.user);
    this.u = new UserModel(data.user);
    this.childAdministrators = data.childAdministrators;
    this.parentAdministrator = data.parentAdministrator;
    this.customers = data.customers;
  }

  get isSuperAdmin() {
    return this.user.role === 'STOOM' || this.user.role === 'SUPERADMIN';
  }
}

export default AdministratorModel;

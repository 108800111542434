import BaseAPI, { URLS } from "./BaseAPI";

class AdministradorAPI {
  /**
   * @param  {Object} data Dados do novo usuário administrador
   */
  static async createAdministrator(data) {
    try {
      const response = await BaseAPI.post(URLS.ACCOUNTS, data);
      return response.status === 201
        ? response
        : { error: "Falha ao cadastrar admin" };
    } catch (e) {
      return { error: "Falha ao cadastrar Administrador" };
    }
  }

  /**
   * @param  {string} uuid Uuid do usuário que será editado
   * @param {object} data dados do usuário que será editado
   */
  static async updateAdministrator(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.ACCOUNTS}/${uuid}`, data);
      if (response.status === 200) return response.data;
      return { error: "Falha ao atualizar informações do usuário" };
    } catch (e) {
      return { error: e.message };
    }
  }

  /**
   * @param  {string} uuid Uuid do usuário que será excluído
   */
  static async deleteAdministrator(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.ACCOUNTS}/${uuid}`);
      if (response.status === 200 || response.status === 204)
        return response.data;
      return { error: "Não foi possível excluir o administrador" };
    } catch (e) {
      return { error: e.message };
    }
  }

  /**Monta uma query de acordo aos dados  */
  /**
   * @param  {object} prop
   * @param  {valor} value
   */
  static async getAdminBy(prop, value) {
    try {
      const url = `${URLS.ACCOUNTS}/${value}/`;
      const params = { by: prop };
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar admin" };
    } catch (e) {
      return { error: e.message };
    }
  }

  /**
   * Busca um usuário admin por username
   * @param  {string} userName username do usuário a ser buscado
   */
  static async getUserAdmin(userName) {
    try {
      const url = `${URLS.ACCOUNTS}/${userName}`;
      const response = await BaseAPI.get(url);
      if (response.data.status) {
        return { error: "Falha ao buscar usuário" };
      }
      return response.data;
    } catch (e) {
      return { error: "Falha ao buscar usuário" };
    }
  }

  /**Busca todos os admins */
  static async getAllAdministrators(params) {
    try {
      const response = await BaseAPI.get(`${URLS.ACCOUNTS}/search`, params);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar usuários" };
    } catch (e) {
      return { error: "Falha ao buscar usuários" };
    }
  }

  static async getChildAdministrators(params) {
    try {
      const response = await BaseAPI.get(`${URLS.ACCOUNTS}/eligible-child-administrators/search`, params);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar usuários" };
    } catch (e) {
      return { error: "Falha ao buscar usuários" };
    }
  }

  static async getParentAdministrators(params) {
    try {
      const response = await BaseAPI.get(`${URLS.ACCOUNTS}/eligible-parent-administrators/search`, params);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar usuários" };
    } catch (e) {
      return { error: "Falha ao buscar usuários" };
    }
  }

  static async getChildren(uuid, page, size) {
    try {
      const response = await BaseAPI.get(`${URLS.ACCOUNTS}/${uuid}/children?page=${page}&size=${size}`);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar usuários" };
    } catch (e) {
      return { error: "Falha ao buscar usuários" };
    }
  }

  static async addChildren(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.ACCOUNTS}/${uuid}/children/add`, data);
      if (response.status === 200) return response.data;
      return { error: "Falha ao inserir administradores" };
    } catch (e) {
      return { error: "Falha ao inserir administradores" };
    }
  }

  static async removeChildren(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.ACCOUNTS}/${uuid}/children/remove`, data);
      if (response.status === 200) return response.data;
      return { error: "Falha ao remover administradores" };
    } catch (e) {
      return { error: "Falha ao remover administradores" };
    }
  }

  static async removeAllChildren(uuid) {
    try {
      const response = await BaseAPI.put(`${URLS.ACCOUNTS}/${uuid}/children/removeAll`);
      if (response.status === 200) return response.data;
      return { error: "Falha ao remover todos administradores" };
    } catch (e) {
      return { error: "Falha ao remover todos administradores" };
    }
  }

  static async getCustomers(uuid, page, size) {
    try {
      const response = await BaseAPI.get(`${URLS.ACCOUNTS}/${uuid}/customers?page=${page}&size=${size}`);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar clientes" };
    } catch (e) {
      return { error: "Falha ao buscar clientes" };
    }
  }

  static async addCustomers(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.ACCOUNTS}/${uuid}/customers/add`, data);
      if (response.status === 200) return response.data;
      return { error: "Falha ao inserir clientes" };
    } catch (e) {
      return { error: "Falha ao inserir clientes" };
    }
  }

  static async removeCustomers(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.ACCOUNTS}/${uuid}/customers/remove`, data);
      if (response.status === 200) return response.data;
      return { error: "Falha ao remover clientes" };
    } catch (e) {
      return { error: "Falha ao remover clientes" };
    }
  }

  static async removeAllCustomers(uuid) {
    try {
      const response = await BaseAPI.put(`${URLS.ACCOUNTS}/${uuid}/customers/removeAll`);
      if (response.status === 200) return response.data;
      return { error: "Falha ao remover todos clientes" };
    } catch (e) {
      return { error: "Falha ao remover todos clientes" };
    }
  }
}

export default AdministradorAPI;

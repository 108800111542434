import BaseModel from "./BaseModel";
import { aliasPaymentType } from "~/helpers/utils/Functions";

export default class PaymentDetailsModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this["@class"] = "br.com.stoom.kernel.model.persistence.PaymentDetails";
    this.valueToBill = data.valueToBill;
    this.valueToRefund = data.valueToRefund;
    this.paymentStatus = data.paymentStatus;
    this.paymentOptionName = data.paymentOptionName;
    this.paymentType = aliasPaymentType(data.paymentType);
    this.installmentValue = data.installmentValue;
    this.installments = data.installments
  }
}

import BaseAPI, { URLS } from "./BaseAPI";

class UnitAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(URLS.UNITS, params);
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar a lista de unidades" };
    } catch (e) {
      return { error: "Falha ao carregar a lista de unidades" };
    }
  }

  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.UNITS}/${uuid}`);
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar unidade" };
    } catch (e) {
      return { error: "Falha ao carregar unidade" };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.UNITS, data);
      return response.status === 201
        ? response.data
        : { error: "Falha ao salvar unidade" };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : "Falha ao salvar unidade",
      };
    }
  }

  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.UNITS}/${uuid}`, data);
      return response.status === 200
        ? response.data
        : { error: "Falha ao atualizar unidade" };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : "Falha ao salvar unidade",
      };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.UNITS}/${uuid}`);
      return response.status === 204
        ? response.data
        : { error: "Falha ao deletar unidade" };
    } catch (e) {
      return { error: "Falha ao deletar unidade" };
    }
  }
}

export default UnitAPI;

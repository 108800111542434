import BaseAPI, { URLS } from "./BaseAPI";

class RolesAPI {
  /**Busca todos os cargos */
  static async getAllRoles(params) {
    try {
      const response = await BaseAPI.get(URLS.ROLES, params);
      if (response.status === 200) return response.data;
      return { error: "Erro inexperado ao busca listas de cargos" };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : "Falha ao buscar lista de cargos",
      };
    }
  }

  /** Busca os cargos por uuid */
  static async getRolesByUuid(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.ROLES}/${uuid}`);
      if (response.status === 200) return response.data;
      return { error: "Erro inexperado ao buscar cargo" };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : "Falha ao buscar cargo",
      };
    }
  }

  static async updateRoles(data, uuid) {
    try {
      const response = await BaseAPI.put(`${URLS.ROLES}/${uuid}`, data);
      if (response.status === 200) return response.data;
      return { error: "Falha ao atualizar informações do cargo" };
    } catch (e) {
      return { error: e?.response?.data?.message
        ? e?.response?.data?.message
        : "Falha ao editar cargo", };
    }
  }

  static async createRoles(data) {
    try {
      const response = await BaseAPI.post(URLS.ROLES, data);
      return response.status === 201
        ? response
        : { error: "Falha ao cadastrar cargo" };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : "Falha ao cadastrar cargo",
      };
    }
  }

  static async deleteRoles(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.ROLES}/${uuid}`);
      return response.status === 204
        ? response
        : { error: "Falha ao deletar cargo" };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : "Falha ao deletar cargo",
      };
    }
  }
}

export default RolesAPI;

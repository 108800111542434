const FilialPermissionEnum = Object.freeze({
  GET: {
    pattern: "/manager/branches/search",
    action: "GET",
  },
  POST: {
    pattern: "/manager/branches/**",
    action: "POST",
  },
  GET_ITEM: {
    pattern: "/manager/branches",
    action: "GET",
  },
  PUT: {
    pattern: "/manager/branches/**",
    action: "PUT",
  },
  DELETE: {
    pattern: "/manager/branches/**",
    action: "DELETE",
  },
  GET_UUID: {
    pattern: "/manager/branches/**",
    action: "GET",
  },
});

export default FilialPermissionEnum;

import { extendObservable } from "mobx";
import AdministratorAPI from "../services/AdministratorAPI";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import AdministratorModel from "../models/AdministratorModel";
import StorageUtil, { KEYS } from "../helpers/utils/StorageUtil";

class AdministradorStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      administrator: undefined,
      administrators: [],
      parentAdministrators: [],
      staffs: [],
      loading: false,
      superAdmin: false,
      totalPages: 0,
      size: 10,
      page: 0,
      sort: "created,desc",
    });
  }

  /** get Current user. */
  get user() {
    return this.userStore.user;
  }

  get userStore() {
    return this.rootStore.usersStore;
  }

  /**Retorna merchanto do usuário */
  get userMerchant() {
    return this.userStore.userMerchant;
  }

  get isSuperAdmin() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === "SUPERADMIN" || currentAuth === "STOOM";
  }

  reset() {
    this.administrator = undefined;
    this.parentAdministrators = [];
  }

  /**Atualiza uma propriedade do novo adminstrator*/
  async onChange(prop, value) {
    const account = this.administrator
      ? this.administrator
      : new AdministratorModel();
    switch (prop) {
      case "username":
        account.user[prop] = value;
        break;
      case "email":
        account.user[prop] = value;
        break;
      case "pass":
        account.user.password = value;
        break;
      case "merchant":
        account.merchant.uuid = value;
        break;
      default:
        account[prop] = value;
    }
    this.administrator = new AdministratorModel(account);
  }

  /**Cria um novo usuário adminin */
  async onCreate() {
    this.loading = true;
    if (!this.isSuperAdmin) this.administrator.merchant = this.userMerchant;
    const data = JSON.stringify(this.administrator);
    const response = await AdministratorAPI.createAdministrator(data);
    this.loading = false;
    if (!response.error) {
      this.administrator = new AdministratorModel();
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Usuário criado com sucesso."
      );
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 15, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = "name";

    sort ? (this.sort = sort) : (this.sort = "name");

    await this.getList();
    return true;
  }

  /**Busca todos os usuarios addmins */
  async getList(
    size = 15,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;
    const response = await AdministratorAPI.getAllAdministrators({
      size,
      page,
      sort,
      ...filters,
    });
    this.loading = false;
    if (response.error) return [];
    this.administrators = response.content.map(
      (usr) => new AdministratorModel(usr)
    );
    this.totalPages = response.totalPages;
    this.page = response.number;

    return this.administrators;
  }

  async getChildAdministrators(
    size = 15,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;
    const response = await AdministratorAPI.getChildAdministrators({
      size,
      page,
      sort,
      ...filters,
    });
    this.loading = false;
    if (response.error) return [];
    const admins = response.content.map(
      (usr) => new AdministratorModel(usr)
    );
    this.totalPages = response.totalPages;
    this.page = response.number;

    return admins;
  }

  async getParentAdministrators(
    size = 15,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;
    const response = await AdministratorAPI.getParentAdministrators({
      size,
      page,
      sort,
      ...filters,
    });
    this.loading = false;
    if (response.error) return [];
    this.parentAdministrators = response.content.map(
      (usr) => new AdministratorModel(usr)
    );
    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  async getExpoxtList(
    size = 99999,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    const response = await AdministratorAPI.getAllAdministrators({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content.map((usr) => new AdministratorModel(usr));
  }

  /**
   * Busca todas as informações de um usuario administrador.
   * @param  {string} userName
   */
  async get(userName) {
    this.loading = true;
    const response = await AdministratorAPI.getUserAdmin(userName);
    this.loading = false;
    if (!response.error) {
      this.administrator = new AdministratorModel(response);
      return this.administrator;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**
   * Busca todas as informações de um usuario administrador.
   * @param  {string} userName
   */
  async getAdminLogin(user) {
    this.administrator = new AdministratorModel(user);
    this.superAdmin = this.administrator.isSuperAdmin;
    return this.administrator;
  }

  /**Busca um usuário customer por um parametro. */
  async getAdminByQuery(value, param = "uuid") {
    this.loading = true;
    const response = await AdministratorAPI.getAdminBy(param, value);
    if (!response.error) {
      this.administrator = new AdministratorModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Busca um usuário customer por um parametro. */
  async onUpdate() {
    this.loading = true;

    // o Kernel barra se o password for not empty ou not null, porem no update ele nao usa o password para nada. Tratativa necessaria para o update fluir.
    this.administrator.user.password = "temp";

    const data = JSON.stringify(this.administrator);
    const response = await AdministratorAPI.updateAdministrator(
      this.administrator.uuid,
      data
    );
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Informações atualizadas com sucesso!"
      );
      this.administrator = new AdministratorModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Deleta administrador */
  async onDelete(uuid) {
    this.loading = true;
    const response = await AdministratorAPI.deleteAdministrator(uuid);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Administrador excluído com sucesso!"
      );
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  getListSelect(administrators = this.administrators) {
    return administrators
      .filter((a) => !a.isSuperAdmin)
      .map((a) => ({ value: a.uuid, label: a.name }));
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getList(this.size, this.page, this.sort, filters);
  }
}
export default AdministradorStore;

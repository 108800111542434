class InstitutionalModel {
  constructor(data = {}) {
    this.uuid = data.uuid;
    this.name = data.name;
    this.ordination = data.ordination;
    this.pageType = data.pageType;
    this.topic = data.topic;
    this.externalLink = data.externalLink;
    this.position = data.position;
    this.content = data.content;
    this.type = "FOOTER_2";
    this.slug = data.slug;
  }
}

export default InstitutionalModel;

import { extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import SalesAPI from "~/services/SalesAPI";
import BestSellingProductsModel from "../models/BestSellingProductsModel";

const initValues = {
  loading: false,
  salesDetails: [],
  sales: [],
  bestSellingProducts: [],
  bestSellingCategories: [],
  filters: {},
  defaultTo: "",
  defaultFrom: "",
  filterDataTo: "",
  filterDataFrom: "",
  size: 15,
  page: 0,
  totalPages: 0,
};

class SalesStore {
  totalPages = 0;
  page = 0;
  size = 15;
  sort = "percentage,desc";
  filter = "";

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, { ...initValues });
  }

  async setFilters(filters) {
    this.filters = filters;

    this.page = 0;
    await this.getSales(this.filters);
  }

  async setPageBestSelling(numPage, size = 10, isPending = false, sort) {
    this.page = numPage;
    this.size = size;

    await this.getSales(this.filters);
    return true;
  }

  async setFiltersGetBestSellingCategories(filters) {
    this.filters = filters;

    this.page = 0;
    await this.getBestSellingCategories(this.filters);
  }

  /**Consulta o relatórios de vendas*/
  async getSales(from, to, subchannel = null, channel = null) {
    this.loading = true;

    const params = {
      paymentsTypes: "INVOICE",
      status: "ANALYSIS",
      from: this.filters.from
        ? this.filters.from + "T00:00:00.556"
        : this.defaultFrom + "T00:00:00.556",
      to: this.filters.to
        ? this.filters.to + "T23:59:59"
        : this.defaultTo + "T23:59:59",
    };

    const response = await SalesAPI.getSales(params);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    else {
      this.sales = response;
      if (this.sales.percentageOrdersConverted === "NaN")
        this.sales.percentageOrdersConverted = 0;
    }
    this.loading = false;
  }

  async setSortGetBestSellingProducts(sort) {
    this.sort = sort;
    await this.getBestSellingProducts();
  }

  async setFiltersGetBestSellingProducts(filters) {
    this.filters = filters;
    await this.getBestSellingProducts(this.filters);
  }

  async setPage(numPage, size = 10, isPending = false, sort) {
    this.page = numPage;
    this.size = size;
    await this.getSales(this.filters);
    return true;
  }

  /**Consulta o Relatório de produtos mais comprados*/
  async getBestSellingProducts(filters) {
    console.log(this.filters)
    this.loading = true;
    const params = {
      from: this.filters.from
        ? this.filters.from + "T00:00:00.556"
        : this.defaultFrom + "T00:00:00.556",
      to: this.filters.to
        ? this.filters.to + "T23:59:59"
        : this.defaultTo + "T23:59:59",
      categoryName: this.filters?.categoryName,
      productName: this.filters?.productName,
      sort: this.sort,
    };

    const size = this.size;
    const page = this.page;

    const response = await SalesAPI.getBestSellingProducts(params, size, page);
    if (!response.error) {
      this.bestSellingProducts = response;
      this.bestSellingProducts = await response.content.map(
        (prd) => new BestSellingProductsModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
      this.loading = false;
      return this.bestSellingProducts;
    }

    this.loading = false;
    return response;
  }

  /**Consulta o Relatório de produtos mais comprados*/
  async getBestSellingCategories() {
    this.loading = true;
    const params = {
      from: this.filters.from
        ? this.filters.from + "T00:00:00.556"
        : this.defaultFrom + "T00:00:00.556",
      to: this.filters.to
        ? this.filters.to + "T23:59:59"
        : this.defaultTo + "T23:59:59",
      categoryName: this.filters?.categoryName?.label,
    };

    const size = this.size;
    const page = this.page;
    const response = await SalesAPI.getBestSellingCategories(
      params,
      size,
      page
    );
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    else {
      this.bestSellingCategories = response;
      this.totalPages = response.totalPages;
      this.page = response.number;
    }
    this.loading = false;
  }

  /**Consulta os detalhes do relatórios de vendas*/
  async getSalesDetails(from, to, page) {
    this.loading = true;
    const params = {
      paymentsTypes: "CREDIT_CARD;INVOICE",
      status: "CART;NEW;ANALYSIS;CANCELED",
      from,
      to,
      page,
      size: 15,
    };
    const response = await SalesAPI.getSalesDetails(params);
    this.salesDetails = [];

    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    else {
      response.content
        .sort((a, b) =>
          a.product_name.toUpperCase() > b.product_name.toUpperCase() ? 1 : -1
        )
        .map((i) => this.salesDetails.push(i));
    }
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.loading = false;
  }

  async getExpoxtListSellingProducts() {
    const params = {
      from: this.filters.from
        ? this.filters?.from + "T00:00:00.556"
        : this.defaultFrom + "T00:00:00.556",
      to: this.filters.to
        ? this.filters?.to + "T23:59:59"
        : this.defaultTo + "T23:59:59",
      categoryName: this.filters?.categoryName?.label,
      productName: this.filters?.productName,
    };

    const size = 99999;

    const response = await SalesAPI.getBestSellingProducts(params, size, 0);

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content;
  }

  async getExpoxtListSellingCategories() {
    const params = {
      from: this.filters.from
        ? this.filters?.from + "T00:00:00.556"
        : this.defaultFrom + "T00:00:00.556",
      to: this.filters.to
        ? this.filters?.to + "T23:59:59"
        : this.defaultTo + "T23:59:59",
      categoryName: this.filters?.categoryName?.label,
    };

    const size = 99999;
    const page = 0;

    const response = await SalesAPI.getBestSellingCategories(
      params,
      size,
      page
    );
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content;
  }

  async getFirstFilterDates() {
    const fDate = new Date();
    const tDate = new Date();

    fDate.setDate(fDate.getDate() - 30);
    const fromYear = fDate.getFullYear();
    const fromMonth =
      fDate.getMonth() + 1 >= 10
        ? fDate.getMonth() + 1
        : `0${fDate.getMonth() + 1}`;
    const fromDay =
      fDate.getDate() >= 10 ? fDate.getDate() : `0${fDate.getDate()}`;
    const fromDate = `${fromYear}-${fromMonth}-${fromDay}`;

    const toYear = tDate.getFullYear();
    const toMonth =
      tDate.getMonth() + 1 >= 10
        ? tDate.getMonth() + 1
        : `0${tDate.getMonth() + 1}`;
    const toDay =
      tDate.getDate() >= 10 ? tDate.getDate() : `0${tDate.getDate()}`;
    const toDate = `${toYear}-${toMonth}-${toDay}`;

    this.defaultTo = toDate;
    this.defaultFrom = fromDate;
  }
}

export default SalesStore;

const EvaluationsPermissionEnum = Object.freeze({
  GET_UUID: {
    pattern: "/manager/products/evaluations/**",
    action: "GET",
  },
  PUT: {
    pattern: "/manager/products/evaluations/**",
    action: "PATCH",
  },
  GET: {
    pattern: "/manager/products/evaluations",
    action: "GET",
  },
});

export default EvaluationsPermissionEnum;

import BaseModel from "./BaseModel";
import OrderStatusModel from "./OrderStatusModel";
import CustomerModel from "./CustomerModel";
import OrderItemsModel from "./OrderItemsModel";
import PaymentDetailsModel from "./PaymentDetailsModel";
import ShippingDetailsModel from "./ShippingDetailsModel";
import { formatNumbers } from "~/helpers/utils/Functions";
import AddressModel from "./AddressModel";

export default class OrderModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.idOrder = data.idOrder;
    this.id_order = data.idOrder;
    this.status = data.status && new OrderStatusModel(data.status);
    this.os = data.status && new OrderStatusModel(data.status);
    this.customer = data.customer && new CustomerModel(data.customer);
    this.c = data.customer && new CustomerModel(data.customer);
    this.orderItems =
      data?.orderItems?.length > 0
        ? data.orderItems.filter((props) => {
            if (props.uuid !== undefined) return new OrderItemsModel(props);
          })
        : [];
    this.shippingDate = data.shippingDate;
    this.paymentDetails = data.paymentDetails
      ? new PaymentDetailsModel(data.paymentDetails)
      : "";
    this.shippingDetails =
      data.shippingDetails && new ShippingDetailsModel(data.shippingDetails);
    this.productsValue = data.productsValue;
    this.quantityOfProducts =
      data?.orderItems?.length > 0 ? data.orderItems.length : 0;
    this.productsDiscounts = data.productsDiscounts;
    this.productsTotal = data.productsTotal;
    this.paymentDiscounts = data.paymentDiscounts;
    this.voucherDiscounts = data.voucherDiscounts;
    this.campaignDiscounts = data.campaignDiscounts;
    this.totalDiscounts = `R$ ${formatNumbers(data.totalDiscounts)}`;
    this.trackingCode = data.trackingCode;
    this.totalValue = `R$ ${formatNumbers(data.totalValue)}`;
    this.totalBilled = `R$ ${formatNumbers(data.totalBilled)}`;
    this.total = `R$ ${formatNumbers(data.total)}`;
    this.subTotal = `R$ ${formatNumbers(
      data.total - data.shippingDetails?.shippingTotal
    )}`;
    this.invoiceDiscounts = data.invoiceDiscounts;
    this.nfNumber = data.nfNumber;
    this.sentErp = data.sentErp;
    this.billed = data.billed;
    this.nfUrl = data.nfUrl;
    this.nfUrlXml = data.nfUrlXml;
    this.trackingEvent = data.trackingEvent;
    this.trackingUrl = data.trackingUrl;
    this.canceledErp = data.canceledErp;
    this.statusChanges = data.statusChanges;
    this.sourceName = data.sourceName;
    this.status_statusName = data.status_statusName;
    this.shippingAddress =
      data.shippingAddress && new AddressModel(data.shippingAddress);
  }
}

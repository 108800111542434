import AdministratorStore from "./AdministratorStore";
import AuthStore from "./AuthStore";
import BannersStore from "./BannersStore";
import BranchesStore from "./BranchesStore";
import CampaignStore from "./CampaignStore";
import RolesStore from "./RolesStore";
import CategoryStore from "./CategoryStore";
import ChannelStore from "./ChannelStore";
import ColorStore from "./ColorStore";
import CouponStore from "./CouponStore";
import CustomerStore from "./CustomerStore";
import CustomerTypeStore from "./CustomerTypeStore";
import DynamicHomeStore from "./DynamicHomeStore";
import MerchantStore from "./MerchantStore";
import NewsLetterStore from "./NewsLetterStore";
import NotificationStore from "./NotificationStore";
import OrderStore from "./OrderStore";
import PerfilStore from "./PerfilStore";
import ProductCommentStore from "./ProductCommentStore";
import ProductStore from "./ProductStore";
import SalesStore from "./SalesStore";
import ShippingClassStore from "./ShippingClassStore";
import ShippingServiceStore from "./ShippingServiceStore";
import SizeStore from "./SizeStore";
import SubChannelStore from "./SubChannelStore";
import UnitStore from "./UnitStore";
import UsersStore from "./UsersStore";
import ZipCodeRangeStore from "./ZipCodeRangeStore";
import PropertiesStore from "./PropertiesStore";
import ProductReviewStore from "./ProductReviewStore";
import AbandonedCartsStore from "./AbandonedCartsStore";
import TopViewProductsStore from "./TopViewProductsStore";
import DashboardStore from "./DashboardStore";
import LogsStore from "./LogsStore";
import TemplateStore from "./TemplateStore";
import StoreSetupStore from "./StoreSetupStore";
import StampStore from "./StampStore";
import InstitutionalStore from "./InstitutionalStore";
import MenuStore from "./MenuStore";
import VoltagemStore from "./VoltagemStore";
import MaterialStore from './MaterialStore';

/**
 * Rootstore guarda a instancia das stores.
 * Assim a comunicação das stores é feita atraves da rootStore.
 *
 * https://mobx.js.org/best/store.html
 */

class RootStore {
  _mobxStores = null;

  constructor(
    serializedStores = {
      usersStore: {},
      menuStore: {},
      administratorStore: {},
      authStore: {},
      branchesStore: {},
      campaignStore: {},
      rolesStore: {},
      categoryStore: {},
      channelStore: {},
      colorStore: {},
      couponStore: {},
      customerStore: {},
      topViewProductsStore: {},
      customerTypeStore: {},
      merchantStore: {},
      newsLetterStore: {},
      logsStore: {},
      productReviewStore: {},
      notificationStore: {},
      orderStore: {},
      perfilStore: {},
      templateStore: {},
      stampStore: {},
      institutionalStore: {},
      productCommentStore: {},
      voltagemStore: {},
      productStore: {},
      salesStore: {},
      abandonedCartsStore: {},
      materialStore: {},
      storeSetupStore: {},
      shippingClassStore: {},
      shippingServiceStore: {},
      propertiesStore: {},
      sizeStore: {},
      subChannelStore: {},
      unitStore: {},
      dashboardStore: {},
      ZipCodeRangeStore: {},
    }
  ) {
    if (this._mobxStores === null) {
      this.usersStore = new UsersStore(this, {
        ...serializedStores.usersStore,
      });
      this.authStore = new AuthStore(this, {
        ...serializedStores.authStore,
      });
      this.propertiesStore = new PropertiesStore(this, {
        ...serializedStores.propertiesStore,
      });
      this.customerTypeStore = new CustomerTypeStore(this, {
        ...serializedStores.customerTypeStore,
      });
      this.menuStore = new MenuStore(this, {
        ...serializedStores.menuStore,
      });
      this.productReviewStore = new ProductReviewStore(this, {
        ...serializedStores.productReviewStore,
      });
      this.administratorStore = new AdministratorStore(this, {
        ...serializedStores.administratorStore,
      });
      this.productStore = new ProductStore(this, {
        ...serializedStores.productStore,
      });
      this.topViewProductsStore = new TopViewProductsStore(this, {
        ...serializedStores.topViewProductsStore,
      });
      this.storeSetupStore = new StoreSetupStore(this, {
        ...serializedStores.storeSetupStore,
      });
      this.voltagemStore = new VoltagemStore(this, {
        ...serializedStores.voltagemStore,
      });
      this.stampStore = new StampStore(this, {
        ...serializedStores.stampStore,
      });
      this.newsLetterStore = new NewsLetterStore(this, {
        ...serializedStores.newsLetterStore,
      });
      this.customerStore = new CustomerStore(this, {
        ...serializedStores.customerStore,
      });
      this.sizeStore = new SizeStore(this, {
        ...serializedStores.sizeStore,
      });
      this.perfilStore = new PerfilStore(this, {
        ...serializedStores.perfilStore,
      });
      this.notificationStore = new NotificationStore(this, {
        ...serializedStores.notificationStore,
      });
      this.branchesStore = new BranchesStore(this, {
        ...serializedStores.branchesStore,
      });
      this.bannersStore = new BannersStore(this, {
        ...serializedStores.bannersStore,
      });
      this.dynamicHomeStore = new DynamicHomeStore(this, {
        ...serializedStores.dynamicHomeStore,
      });
      this.shippingServiceStore = new ShippingServiceStore(this, {
        ...serializedStores.shippingServiceStore,
      });
      this.categoryStore = new CategoryStore(this, {
        ...serializedStores.categoryStore,
      });
      this.orderStore = new OrderStore(this, {
        ...serializedStores.orderStore,
      });
      this.productCommentStore = new ProductCommentStore(this, {
        ...serializedStores.productCommentStore,
      });
      this.colorStore = new ColorStore(this, {
        ...serializedStores.colorStore,
      });
      this.unitStore = new UnitStore(this, {
        ...serializedStores.unitStore,
      });
      this.campaignStore = new CampaignStore(this, {
        ...serializedStores.campaignStore,
      });
      this.rolesStore = new RolesStore(this, {
        ...serializedStores.rolesStore,
      });
      this.merchantStore = new MerchantStore(this, {
        ...serializedStores.merchantStore,
      });
      this.abandonedCartsStore = new AbandonedCartsStore(this, {
        ...serializedStores.abandonedCartsStore,
      });
      this.salesStore = new SalesStore(this, {
        ...serializedStores.salesStore,
      });
      this.channelStore = new ChannelStore(this, {
        ...serializedStores.channelStore,
      });
      this.shippingClassStore = new ShippingClassStore(this, {
        ...serializedStores.shippingClassStore,
      });
      this.subChannelStore = new SubChannelStore(this, {
        ...serializedStores.subChannelStore,
      });
      this.institutionalStore = new InstitutionalStore(this, {
        ...serializedStores.institutionalStore,
      });
      this.logsStore = new LogsStore(this, {
        ...serializedStores.logsStore,
      });
      this.templateStore = new TemplateStore(this, {
        ...serializedStores.templateStore,
      });
      this.storeSetupStore = new StoreSetupStore(this, {
        ...serializedStores.storeSetupStore,
      });
      this.materialStore = new MaterialStore(this, {
        ...serializedStores.materialStore,
      });
      this.couponStore = new CouponStore(this, {
        ...serializedStores.couponStore,
      });
      this.zipCodeRangeStore = new ZipCodeRangeStore(this, {
        ...serializedStores.zipCodeRangeStore,
      });
      this.dashboardStore = new DashboardStore(this, {
        ...serializedStores.dashboardStore,
      });
    }
  }

  get mobxStores() {
    return (this._mobxStores = {
      usersStore: this.usersStore,
      customerTypeStore: this.customerTypeStore,
      authStore: this.authStore,
      administratorStore: this.administratorStore,
      customerStore: this.customerStore,
      perfilStore: this.perfilStore,
      branchesStore: this.branchesStore,
      bannersStore: this.bannersStore,
      dynamicHomeStore: this.dynamicHomeStore,
      categoryStore: this.categoryStore,
      orderStore: this.orderStore,
      menuStore: this.menuStore,
      productCommentStore: this.productCommentStore,
      propertiesStore: this.propertiesStore,
      productReviewStore: this.productReviewStore,
      storeSetupStore: this.storeSetupStore,
      abandonedCartsStore: this.abandonedCartsStore,
      productStore: this.productStore,
      salesStore: this.salesStore,
      merchantStore: this.merchantStore,
      notificationStore: this.notificationStore,
      materialStore: this.materialStore,
      unitStore: this.unitStore,
      colorStore: this.colorStore,
      shippingServiceStore: this.shippingServiceStore,
      sizeStore: this.sizeStore,
      logsStore: this.logsStore,
      newsLetterStore: this.newsLetterStore,
      campaignStore: this.campaignStore,
      rolesStore: this.rolesStore,
      channelStore: this.channelStore,
      voltagemStore: this.voltagemStore,
      subChannelStore: this.subChannelStore,
      templateStore: this.templateStore,
      couponStore: this.couponStore,
      shippingClassStore: this.shippingClassStore,
      zipCodeRangeStore: this.zipCodeRangeStore,
      topViewProductsStore: this.topViewProductsStore,
      stampStore: this.stampStore,
      institutionalStore: this.institutionalStore,
      dashboardStore: this.dashboardStore,
    });
  }
}

let store = null;

export function initializeStores(props, isServer) {
  if (isServer && typeof window === "undefined") {
    // Se estiver no servidor, retorne uma nova instância da loja
    return new RootStore(props);
  } else {
    // Se estiver no lado do cliente, verifique se a loja já foi inicializada
    if (store === null) {
      store = new RootStore(props);
    }

    return store;
  }
}

export default RootStore;

import BaseAPI from "./BaseAPI";

class ReportLogAPI {
  static _exception(e, message) {
    return { error: message };
  }

  /**
   *@description Consulta LOGINS
   *@param {string} from data
   *@param {string} to data
   */
  static async getLogins(params, size, page) {
    try {
      let url;
      if (params.user) {
        url = `/manager/reports/logins?page=${page}&size=${size}&sort=created&search=user_username;"${params.user}":created:"${params.from} 00-00-00;${params.to} 23-59-59"`;
      } else {
        url = `/manager/reports/logins?page=${page}&size=${size}&sort=created&search=created:"${params.from} 00-00-00;${params.to} 23-59-59"`;
      }

      const response = await BaseAPI.put(url);
      if (response.status === 200) return response.data;
      return { error: "Falha ao consultar LOGS DE ACESSO." };
    } catch (e) {
      return this._exception(e, "Falha ao consultar LOGS DE ACESSO.");
    }
  }
}

export default ReportLogAPI;

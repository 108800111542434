import { extendObservable } from "mobx";
import { v4 as uuidv4 } from 'uuid';
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import CouponModel from "~/models/CouponModel";
import PromotionApplicationTypeEnum from "~/helpers/utils/enums/PromotionApplicationTypeEnum";
import {
  DateRestriction,
  PriceRestriction,
  ProductCategoryRestriction,
  CustomerRestriction,
  PointOfSaleRestriction,
  ZipCodeRestriction,
  FirstPurchaseRestriction,
} from "~/models/RestrictionModel";
import CouponAPI from "~/services/CouponAPI";
import { isArrayEmpty, onlyUnique } from "~/helpers/utils/Functions";
import DiscountModel from "../models/Discount";
import CustomerAPI from "../services/CustomerAPI";
import CustomerModel from "~/models/CustomerModel";
import {
  clearCPFAndCNPJMask,
  converterStringToNumber,
  generateTreeUuid,
} from "../helpers/utils/Functions";

const initValues = {
  loading: false,
  totalPages: 0,
  page: 0,
  size: 15,
  isAllProducts: false,
  coupon: new CouponModel(),
  shippingRange: false,
  clients: false,
  defaultTo: "",
  defaultCategory: "",
  defaultFrom: "",
  cupomReports: [],
  priceRestriction: new PriceRestriction(),
  dateRestriction: new DateRestriction(),
  productCategoryRestriction: new ProductCategoryRestriction(),
  pointOfSaleRestriction: new PointOfSaleRestriction(),
  customerRestriction: new CustomerRestriction(),
  firstPurchaseRestriction: new FirstPurchaseRestriction(),
  discount: new DiscountModel(),
  voucher: "",
  zipCodeRestrictions: new ZipCodeRestriction(),
  zipCodeList: [],
  coupons: [],
  documents: [],
};

class CouponStore {
  totalPages = 0;
  page = 0;
  size = 15;
  sort = "created,desc";

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, { ...initValues });
  }

  async setPage(numPage, size = 15, useLogin = true, sort) {
    this.page = numPage;
    this.size = size;
    sort && (this.sort = sort);
    await this.getList();
    return this.totalPages;
  }

  reset() {
    this.coupon = new CouponModel();
    this.dateRestriction = new DateRestriction();
    this.documents = [];
    this.totalPages = 0;
    this.size = 15;
    this.page = 0;
    this.loading = false;
    this.applicationType = undefined;
    this.priceRestriction = new PriceRestriction();
    this.productCategoryRestriction = new ProductCategoryRestriction();
    this.pointOfSaleRestriction = new PointOfSaleRestriction();
    this.customerRestriction = new CustomerRestriction();
    this.firstPurchaseRestriction = new FirstPurchaseRestriction();
    this.discount = new DiscountModel();
    this.voucher = "";
    this.zipCodeRestrictions = new ZipCodeRestriction();
    this.coupons = [];
    this.zipCodeList = [];
    this.shippingRange = false;
  }

  async getList(
    size = 15,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;

    const response = await CouponAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return [];
    }
    this.loading = false;

    this.coupons = response.content;
    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  async getExpoxtList(
    size = 1000,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    const response = await CouponAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content.map((usr) => new CouponModel(usr));
  }

  setValueDiscount(type, value) {
    const discount = this.discount;

    if (type === "productsAbsoluteDiscount") {
      discount.productsAbsoluteDiscount =
        value === "" ? 0 : converterStringToNumber(value);
      discount.productsPercentageDiscount = 0;
    } else if (type === "productsPercentageDiscount") {
      discount.productsPercentageDiscount = value.length > 0 ? value / 100 : 0;
      discount.productsAbsoluteDiscount = 0;
    }

    this.discount = discount;
  }

  setValueFirstPurchaseRestriction(value) {
    if (value === true) {
      this.firstPurchaseRestriction.excludes = value;
    } else {
      this.firstPurchaseRestriction.excludes = value;
      const restriction = this.coupon.restrictions.filter(
        (item) =>
          item["@class"] !==
          "br.com.stoom.kernel.model.persistence.restriction.FirstPurchaseRestriction"
      );
      this.coupon.restrictions = restriction;
    }
  }

  setValueShippingRange(value) {
    if (this.zipCodeRestrictions === undefined) {
      this.zipCodeRestrictions = new ZipCodeRestriction();
    }

    if (value != undefined) {
      this.shippingRange = value;
      this.zipCodeRestrictions.excludes = value;
    }
  }
  setValueClients(value) {
    if (value !== undefined) {
      const customer = this.customerRestriction;
      customer.excludes = value;
      this.customerRestriction = customer;
    }
  }

  async getByUuid(campaignUuid) {
    this.loading = true;

    const response = await CouponAPI.getByUuid(campaignUuid);
    if (!response.error) {
      this.coupon = new CouponModel(response);
      this.discount = this.coupon.discount;
      if (response?.restrictions && response?.restrictions?.length > 0) {
        this.organizeRestrictions(response?.restrictions);
        let zipCodeTemp = [];

        response.restrictions?.map((restriction) => {
          if (restriction['@class'].includes('ZipCodeRestriction')) {
            zipCodeTemp.push(restriction);
          }
        });
        this.zipCodeList = zipCodeTemp[0]?.zipCodeRangeRestrictions;

        if (response.restrictions.length > 0) {
          const categoryFind = response.restrictions.find(
            (item) =>
              item['@class'] ===
              'br.com.stoom.kernel.model.persistence.restriction.ProductCategoryRestriction'
          );

          if (categoryFind !== undefined) {
            this.defaultCategory = generateTreeUuid(categoryFind?.categories);
          }
        }

        this.zipCodeRestrictions = zipCodeTemp[0];
        const productsRestriction = response.restrictions?.map((restriction) =>
          restriction['@class'].includes('ProductCategoryRestriction')
        );
        if (productsRestriction && productsRestriction?.products?.length > 0) {
          this.productCategoryRestriction.products = [
            ...productsRestriction.products,
          ];
        }
      }
      this.loading = false;
      return this.coupon;
    }
    this.loading = false;
    return false;
  }

  async getCustomer(filters) {
    const response = await CustomerAPI.getList({
      size: 15,
      page: 0,
      ...filters,
    });

    if (!response.error) {
      if (response.content && response.content.length > 0) {
        return response.content[0];
      }
      return [];
    }
    return [];
  }

  handleSearchDocument = (documents) => {
    documents.map(async (item) => {
      let data;

      let search = "";
      if (item !== "" && item !== undefined) {
        search += `,document;"${clearCPFAndCNPJMask(item)}"`;
      }

      data = await this.getCustomer({ search });
      this.documents.push(
        new CustomerModel({
          uuid: data.uuid,
          firstName: data.firstName,
          active: data.active,
          document: data.document,
        })
      );
    });
    this.loading = false;
  };

  async organizeRestrictions(restrictions) {
    restrictions.map((r) => {
      switch (r["@class"]) {
        case "br.com.stoom.kernel.model.persistence.restriction.ProductCategoryRestriction":
          this.productCategoryRestriction = r;
          break;
        case "br.com.stoom.kernel.model.persistence.restriction.DateRestriction":
          this.dateRestriction = r;
          break;
        case "br.com.stoom.kernel.model.persistence.restriction.PriceRestriction":
          this.priceRestriction = r;
          break;
        case "br.com.stoom.kernel.model.persistence.restriction.CustomerRestriction":
          this.clients = r.excludes;

          if (r.documents.length > 0) {
            this.handleSearchDocument(r.documents);
          }
          break;
        case "br.com.stoom.kernel.model.persistence.restriction.ZipCodeRestriction":
          this.shippingRange = r?.excludes;
          break;
        case "br.com.stoom.kernel.model.persistence.restriction.FirstPurchaseRestriction":
          this.firstPurchaseRestriction = r;
          break;
        default:
          break;
      }
    });
  }

  checkedAllProducts() {
    this.isAllProducts = true;
    this.productCategoryRestriction.categories = [];
    this.productCategoryRestriction.products = undefined;
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getList(this.size, this.page, this.sort, filters);
  }

  removeAccents(newStringComAcento) {
    let string = newStringComAcento;
    const mapaAcentosHex = {
      a: /[\xE0-\xE6]/g,
      e: /[\xE8-\xEB]/g,
      i: /[\xEC-\xEF]/g,
      o: /[\xF2-\xF6]/g,
      u: /[\xF9-\xFC]/g,
      c: /\xE7/g,
      n: /\xF1/g,
    };

    for (var letra in mapaAcentosHex) {
      var expressaoRegular = mapaAcentosHex[letra];
      string = string.replace(expressaoRegular, letra);
    }

    return string.toLowerCase().replace(/ /g, "_");
  }

  handleCampaignChange(prop, value) {
    const { coupon } = this;
    switch (prop) {
      case "excludes":
        this.productCategoryRestriction[prop] = value;
        break;
      case "maxUses":
        coupon.maxUses = parseInt(value);
        this.coupon = new CouponModel(coupon);
        break;
      case "voucherName":
        if (!coupon.uuid) {
          this.voucher = { identifier: this.removeAccents(value) };
        }
        coupon.name = value;
        break;
      default:
    }

    coupon[prop] = value;
    this.coupon = new CouponModel(coupon);
  }

  handleChangeDate(prop, value) {
    if (prop === "maxDate") {
      this.dateRestriction.maxDate = value;
    } else {
      this.dateRestriction.minDate = value;
    }
  }

  changePriceRestriction(prop, value) {
    const { priceRestriction } = this;
    priceRestriction[prop] = value;
    this.priceRestriction = priceRestriction;
  }

  handleChangeDiscount(prop, value) {
    const updated = { ...this.coupon };

    if (typeof value !== "number") value = value.replace(",", ".");
    if (updated.discounts.length > 0) updated.discounts[0][prop] = value / 100;

    this.coupon = new CouponModel(updated);
  }

  async onSave() {
    this.loading = true;
    this.coupon.restrictions = this.getRestritions();
    this.coupon.discount = this.discount;
    this.coupon.vouchers = [this.voucher];

    if (this.coupon.maxUses === 0) {
      this.coupon.maxUses = undefined;
    }

    const response = await CouponAPI.save(this.coupon);
    this.loading = false;

    if (!response.error) {
      this.coupon = response.data;
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Cupom adicionada com sucesso!"
      );
      this.reset();
      return true;
    }

    this.toastHelper.notify(STATUS_HELPER.ERROR, "Erro ao adicionar a cupom");
    return false;
  }

  async onUpdate() {
    this.loading = true;
    this.coupon.discount = this.discount;

    this.coupon.restrictions = this.getRestritions();
    const response = await CouponAPI.update(this.coupon, this.coupon.uuid);
    this.loading = false;

    if (!response.error) {
      this.reset();

      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Cupom atualizada com sucesso!"
      );

      return true;
    }

    this.toastHelper.notify(STATUS_HELPER.ERROR, "Erro ao atualizar a cupom");
    return false;
  }

  getRestritions() {
    const restrictions = [this.dateRestriction];

    if (
      this.productCategoryRestriction.categories.length >= 1 ||
      this.productCategoryRestriction.products.length >= 1
    ) {
      restrictions.push(this.productCategoryRestriction);
    }

    if (
      this.priceRestriction.maxPrice !== undefined ||
      this.priceRestriction.minPrice !== undefined
    ) {
      restrictions.push(this.priceRestriction);
    }

    if (this.firstPurchaseRestriction?.excludes === true) {
      restrictions.push(this.firstPurchaseRestriction);
    }

    if (this.zipCodeRestrictions !== undefined) {
      console.log('GET RESTRITIONS')
      console.log('this.zipCodeRestrictions=>', this.zipCodeRestrictions)
      const newData = this.zipCodeRestrictions.zipCodeRangeRestrictions.map(
        (item) => {
          if (item.uuid.startsWith('created ')) {
            return { ...item, uuid: item.uuid.replace('created ', '') };
          } else {
            return item;
          }
        }
      );
      console.log('newData', newData)
      this.zipCodeRestrictions.zipCodeRangeRestrictions = newData;

      const allDeleted =
        this.zipCodeRestrictions.zipCodeRangeRestrictions.every(
          (restriction) => restriction.deleted === true
        );

      if (allDeleted === true) {
        this.zipCodeRestrictions.deleted = true;
      } else {
        this.zipCodeRestrictions.deleted = false;
      }

      console.log('zipcoderestrictions', this.zipCodeRestrictions)
      restrictions.push(this.zipCodeRestrictions);
    }

    if (this.documents.length > 0) {
      let arrayDocuments = [];
      this.documents.map((item) => {
        arrayDocuments.push(item.document);
      });
      this.customerRestriction.documents = arrayDocuments;
      restrictions.push(this.customerRestriction);
    }

    return restrictions;
  }

  async handleAddZipCode(data) {
    if (this.zipCodeRestrictions === undefined) {
      this.zipCodeRestrictions = new ZipCodeRestriction();
    }

    if (this.zipCodeList === undefined) {
      this.zipCodeList = [];
    }

    const addItem = {
      '@class': 'br.com.stoom.kernel.model.dto.ZipCodeRangeRestrictions',
      endZipCode: data.finalZipCode,
      startZipCode: data.initialZipCode,
      deleted: false,
      uuid: 'created' + ' ' + uuidv4(),
    };

    this.zipCodeList.push(addItem);
    this.zipCodeRestrictions.zipCodeRangeRestrictions = this.zipCodeList;
  }

  handleDeleteZipCode(data) {
    if (data.uuid.includes('created')) {
      const filter = this.zipCodeRestrictions.zipCodeRangeRestrictions.filter(
        (item) => item.uuid !== data.uuid
      );
      this.zipCodeList = filter;
      this.zipCodeRestrictions.zipCodeRangeRestrictions = filter;
    } else {
      const newData = this.zipCodeRestrictions.zipCodeRangeRestrictions.map(
        (item) => {
          if (item.uuid === data.uuid) {
            return { ...item, deleted: true };
          } else {
            return item;
          }
        }
      );
      this.zipCodeRestrictions.zipCodeRangeRestrictions = newData;
      this.zipCodeList = newData;
    }
  }

  changeApplicationType(isEdit) {
    if (!isEdit) {
      this.productCategoryRestriction.products = [];
      this.productCategoryRestriction.categories = [];
    }
  }

  applicationTypeVerify() {
    let applicationType = false;
    if (
      this.productCategoryRestriction.categories &&
      this?.productCategoryRestriction?.categories?.length > 0
    ) {
      applicationType = {
        label: "Categoria",
        value: PromotionApplicationTypeEnum.CATEGORIA,
      };
    } else if (
      this.productCategoryRestriction.products &&
      this.productCategoryRestriction.products.length > 0
    ) {
      applicationType = {
        label: "Produto",
        value: PromotionApplicationTypeEnum.PRODUTO,
      };
      this.isAllProducts = true;
    }
    return applicationType;
  }

  setCategory(categories) {
    const selected = categories.map((category) => {
      return {
        uuid: category?.uuid,
        categoryName: category?.categoryName,
        "@class": category["@class"],
      };
    });

    this.categoriesRemove = this.productCategoryRestriction.categories.filter(
      (cat) => !selected.some((mCat) => mCat.uuid === cat.uuid)
    );

    this.defaultCategory = generateTreeUuid(categories);

    this.categoriesAdd = selected.filter(
      (cat) =>
        !this.productCategoryRestriction.categories.some(
          (mCat) => mCat.uuid === cat.uuid
        )
    );

    this.productCategoryRestriction.categories = [
      ...this.productCategoryRestriction.categories,
      ...this.categoriesAdd,
    ]
      .filter((f) => this.categoriesRemove.indexOf(f) < 0)
      .filter(onlyUnique);
    this.productCategoryRestriction.products = [];

    return [
      ...this.productCategoryRestriction.categories,
      ...this.categoriesAdd,
    ]
      .filter((f) => this.categoriesRemove.indexOf(f) < 0)
      .filter(onlyUnique);
  }

  removeCategory() {
    this.productCategoryRestriction.categories = [];
    this.productCategoryRestriction.products = [];
    this.defaultCategory = generateTreeUuid([]);
  }

  async deleteCupon(campaignUuid) {
    const response = await CouponAPI.delete(campaignUuid);

    if (!response.error) {
      await this.getList();
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Cupom deletada com sucesso"
      );
      return true;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, "Falha ao deletar a cupom");
  }

  addProduct(product) {
    if (isArrayEmpty(this.productCategoryRestriction.products)) {
      this.productCategoryRestriction.products = [];
    }

    this.productCategoryRestriction.products.push(product);
    this.productCategoryRestriction.categories = [];
  }

  removeProduct(product) {
    if (isArrayEmpty(this.productCategoryRestriction.products)) {
      return;
    }

    this.productCategoryRestriction.products =
      this.productCategoryRestriction.products.filter(
        (f) => f.uuid !== product.uuid
      );
    this.productCategoryRestriction.categories = [];
    this.renderAttribute = !this.renderAttribute;
  }

  async addDocument(data) {
    const documentExists = this.documents.some(
      (item) => item.document === data.document
    );

    if (!documentExists) {
      this.documents.push(data);
    }
  }

  removeDocument(item) {
    const docIndex = this.documents.findIndex((value) => value === item);

    const filter = this.documents.filter(
      (document, index) => index !== docIndex
    );

    this.documents = filter;
  }

  clearDocument() {
    this.documents = [];
  }

  clearProducts() {
    this.productCategoryRestriction.products = [];
  }

  //Reports
  async getFirstFilterDates() {
    const fDate = new Date();
    const tDate = new Date();

    fDate.setDate(fDate.getDate() - 30);
    const fromYear = fDate.getFullYear();
    const fromMonth =
      fDate.getMonth() + 1 >= 10
        ? fDate.getMonth() + 1
        : `0${fDate.getMonth() + 1}`;
    const fromDay =
      fDate.getDate() >= 10 ? fDate.getDate() : `0${fDate.getDate()}`;
    const fromDate = `${fromYear}-${fromMonth}-${fromDay}`;

    const toYear = tDate.getFullYear();
    const toMonth =
      tDate.getMonth() + 1 >= 10
        ? tDate.getMonth() + 1
        : `0${tDate.getMonth() + 1}`;
    const toDay =
      tDate.getDate() >= 10 ? tDate.getDate() : `0${tDate.getDate()}`;
    const toDate = `${toYear}-${toMonth}-${toDay}`;

    this.defaultTo = toDate;
    this.defaultFrom = fromDate;
  }

  async setFiltersCupomReports(filters) {
    this.filters = filters;
    await this.getCupomReports(this.filters);
  }

  async getCupomReports() {
    this.loading = true;
    const params = {
      from: this.filters?.from
        ? this.filters?.from + "T00:00:00"
        : this.defaultFrom + "T00:00:00",
      to: this.filters?.to
        ? this.filters?.to + "T00:00:00"
        : this.defaultTo + "T23:59:59",
      customerEmail: this.filters?.customerEmail?.length > 0 ? this.filters?.customerEmail : undefined,
      finalZipCode: this.filters?.finalZipCode,
      initialZipCode: this.filters?.initialZipCode,
      status: this.filters?.status?.value,
      categoryUuid: this.filters?.categoryUuid?.value,
      productUuid: this.filters?.productUuid?.length > 0 ? this.filters?.productUuid : undefined,
      customerDocument: this.filters?.customerDocument?.length > 0 ? this.filters?.customerDocument : undefined,
      couponIdentifier: this.filters?.couponIdentifier?.length > 0 ? this.filters?.couponIdentifier : undefined,
    };

    const size = this.size;
    const page = 0;

    const response = await CouponAPI.getCupomReports(params, size, page);
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    else {
      this.cupomReports = response;
      this.totalPages = response.totalPages;
      this.page = response.number;
    }
    this.loading = false;
  }

  async setPageReports(numPage, size = 15) {
    this.page = numPage;
    this.size = size;
    await this.getCupomReports();
    return true;
  }

  async getExportReporttList() {
    const params = {
      from: this.filters?.from
        ? this.filters?.from + "T00:00:00"
        : this.defaultFrom + "T00:00:00",
      to: this.filters?.to
        ? this.filters?.to + "T00:00:00"
        : this.defaultTo + "T23:59:59",
      customerEmail: this.filters?.customerEmail
        ? this.filters?.customerEmail
        : undefined,
      finalZipCode: this.filters?.finalZipCode,
      initialZipCode: this.filters?.initialZipCode,
      status: this.filters?.status?.value,
      categoryUuid: this.filters?.categoryUuid?.value,
      productUuid: this.filters?.productUuid
        ? this.filters?.productUuid
        : undefined,
      customerDocument: this.filters?.customerDocument
        ? this.filters?.customerDocument
        : undefined,
    };

    const size = 99999;

    const response = await CouponAPI.getCupomReports(params, size, 0);
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response;
  }
}

export default CouponStore;

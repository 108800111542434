const AccountsPermissionEnum = Object.freeze({
  POST: {
    pattern: '/manager/accounts',
    action: 'POST',
  },
  PUT: {
    pattern: '/manager/accounts/**',
    action: 'PUT',
  },
  DELETE: {
    pattern: '/manager/accounts/**',
    action: 'DELETE',
  },
  GET: {
    pattern: '/manager/accounts/search',
    action: 'GET',
  },
  GET_UUID: {
    pattern: '/manager/accounts/**',
    action: 'GET',
  },
});

export default AccountsPermissionEnum;

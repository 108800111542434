import BaseModel from "./BaseModel";

class ProductComentModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.baseProductName = data.baseProductName;
    this.baseProductUuid = data.baseProductUuid;
    this.email = data.email;
    this.name = data.name;
    this.rating = data.rating;
    this.status = data.status;
    this.title = data.title;
    this.uuid = data.uuid;
    this.description = data.description;
  }
}

export default ProductComentModel;

import CustomerType from "./CustomerTypeModel";
import BaseModel from "./BaseModel";

export default class PriceModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.price = data.price;
    this.fakePrice = data.fakePrice;
    this.customerType = new CustomerType({
      uuid: "46306579-82d1-47b2-9536-9e6d23a5aebe",
      created: "2023-07-13T17:32:57.423+00:00",
      modified: "2023-07-13T17:32:57.423+00:00",
      active: true,
      deleted: false,
      name: "F/J",
    });
  }
}

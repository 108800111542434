const CustomerPermissionEnum = Object.freeze({
  POST: {
    pattern: '/manager/customers/**',
    action: 'POST',
  },
  PUT: {
    pattern: '/manager/customers/**',
    action: 'PUT',
  },
  DELETE: {
    pattern: '/manager/customers/**',
    action: 'DELETE',
  },
  GET: {
    pattern: '/manager/customers/search',
    action: 'GET',
  },
  GET_UUID: {
    pattern: '/manager/customers/**',
    action: 'GET',
  },
  CSV_CREATE: {
    pattern: '/manager/customers/create-by-csv',
    action: 'POST',
  },
});

export default CustomerPermissionEnum;

import BaseAPI, { URLS } from "./BaseAPI";

class CampaignAPI {
  /**Retorno generico de excessões */
  static _exception(e, message) {
    return { error: message };
  }

  /**
   * @param  {object} params - parametros de paginação
   */
  static async getList(params) {
    try {
      const response = await BaseAPI.get(`${URLS.COUPONS}/search`, params);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar lista de cupons" };
    } catch (e) {
      return this._exception(e, "Falha ao buscar lista de cupons");
    }
  }

  /**
   * Request que troca a prop active uma campanha.
   * @param  {String} uuid - Uuid do cupom que será atualizada
   */
  static async switchActiveFlag(uuid) {
    try {
      const response = await BaseAPI.patch(`${URLS.COUPONS}/${uuid}`);
      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 204
      )
        return response;
      return { error: "Erro inesperado ao ativar/desativar o cupom" };
    } catch (e) {
      return this._exception(e, "Falha ao ativar/desativar o cupom");
    }
  }

  /**
   * Request que cria nova campanha.
   * @param  {Object} campaign - Objeto do cupom que será criada
   */
  static async save(campaign) {
    try {
      const response = await BaseAPI.post(URLS.COUPONS, campaign);
      if (response.status === 200 || response.status === 201) return response;
      return { error: "Erro inesperado ao cadastrar o cupom" };
    } catch (e) {
      return this._exception(e, "Falha ao cadastrar o cupom");
    }
  }

  /**
   * Request que atualiza uma campanha.
   * @param  {Object} campaign - Objeto do cupom que será atualizada
   * @param  {String} uuid - Uuid do cupom que será atualizada
   */
  static async update(campaign, uuid) {
    try {
      const response = await BaseAPI.put(`${URLS.COUPONS}/${uuid}`, campaign);
      if (response.status === 200 || response.status === 201) return response;
      return { error: "Erro inesperado ao atualizar o cupom" };
    } catch (e) {
      return this._exception(e, "Falha ao atualizar o cupom");
    }
  }

  /**
   * Request que busca campanha pelo uuid.
   * @param  {String} uuid - Uuid do cupom
   */
  static async getByUuid(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.COUPONS}/${uuid}`);
      if (response.status === 201 || response.status === 200)
        return response.data;
      return { error: "Erro ao recuperar informação do cupom!" };
    } catch (e) {
      return this._exception(e, "Erro ao recuperar informação do cupom!");
    }
  }

  /**
   * Request que deleta uma campanha.
   * @param  {String} uuid - Uuid do cupom que será atualizada
   */
  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.COUPONS}/${uuid}`);
      if (response.status === 200 || response.status === 201) return response;
      return { error: "Erro inesperado ao deletar o cupom" };
    } catch (e) {
      return this._exception(e, "Falha ao deletar o cupom");
    }
  }

  /**
   *@description Consulta de relatorio de cupons
   *@param {string} from data
   *@param {string} to data
   */
  static async getCupomReports(params, size, page) {
    try {
      const url = `${URLS.CUPONS_REPORTS}?size=${size}&page=${page}`;
      const response = await BaseAPI.put(url, { ...params });
      if (response.status === 200) return response.data;
      return { error: "Falha ao consultar cupons" };
    } catch (e) {
      return this._exception(e, "Falha ao consultar cupons");
    }
  }
}

export default CampaignAPI;

/* eslint-disable no-console */
import BaseAPI, { URLS } from "./BaseAPI";

export const reportRequestDTO = {
  "@class": "br.com.stoom",
};

class SalesAPI {
  static _exception(e, message) {
    return { error: message };
  }

  /**
   *@description Consulta o relatório de vendas
   *@param {string} tab rota que será consultada (city ou state ou general)
   *@param {string} from data
   *@param {string} to data
   */
  static async getSales(params) {
    try {
      const url = `${URLS.SALES}`;
      const response = await BaseAPI.put(url, params);
      if (response.status === 200) return response.data;
      return { error: "Falha ao consultar vendas." };
    } catch (e) {
      return this._exception(e, "Falha ao consultar vendas.");
    }
  }

  /**
   *@description Consulta os detalhes do relatório de vendas
   *@param {string} from data
   *@param {string} to data
   */
  static async getSalesDetails(params) {
    try {
      const url = `${URLS.SALES_DETAILS}`;
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return { error: "Falha ao consultar vendas." };
    } catch (e) {
      return this._exception(e, "Falha ao consultar vendas.");
    }
  }

  /**
   *@description Consulta dados de abandono de carrinho
   *@param {string} from data
   *@param {string} to data
   */
  static async getAbandonedCarts(params, size, page) {
    try {
      const url = `${URLS.SALES_ABANDONED_CARTS}?size=${size}&page=${page}`;
      const response = await BaseAPI.put(url, {
        ...params,
      });
      if (response.status === 200) return response.data;
      return { error: "Falha ao consultar vendas." };
    } catch (e) {
      return this._exception(e, "Falha ao consultar carrinhos abandonados.");
    }
  }

  static async getListAbandonedCarts(params) {
    try {
      const response = await BaseAPI.get(`${URLS.ORDER}/search`, params);
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar a lista de carrinhos abandonados" };
    } catch (e) {
      return { error: "Falha ao carregar a lista de carrinhos abandonados" };
    }
  }

  static async getReportsCard(params, size, page) {
    try {
      const response = await BaseAPI.put(`${URLS.REPORTS_CARD}`, params);
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar informações de relatorio" };
    } catch (e) {
      return { error: "Falha ao carregar informações de relatorio" };
    }
  }

  /**
   *@description Consulta produtos mais vendidos
   *@param {string} from data
   *@param {string} to data
   */
  static async getBestSellingProducts(params, size, page) {
    try {
      const response = await BaseAPI.put(
        `/manager/reports/best-selling-products?size=${size}&page=${page}${params.sort ? "&sort="+params.sort : ""}`,
        params
      );
      if (response.status === 200) return response.data;
      return { error: "Falha ao consultar produtos mais vendidos." };
    } catch (e) {
      return this._exception(e, "Falha ao consultar produtos mais comprados.");
    }
  }

  /**
   *@description Consulta CATEGORIAS mais vendidos
   *@param {string} from data
   *@param {string} to data
   */
  static async getBestSellingCategories(params, size, page) {
    try {
      const url = `${URLS.SALES_BEST_SELLING_CATEGORIES}?size=${size}&page=${page}`;
      const response = await BaseAPI.put(url, {
        ...params,
      });
      if (response.status === 200) return response.data;
      return { error: "Falha ao consultar CATEGORIAS mais vendidas." };
    } catch (e) {
      return this._exception(e, "Falha ao consultar CATEGORIAS mais vendidas.");
    }
  }

  /**
   *@description Consulta produtos mais vistos
   *@param {string} from data
   *@param {string} to data
   */
  static async getTopViewProducts(params, size, page) {
    try {
      const url = `${URLS.SALES_TOP_VIEW_PRODUCTS}?size=${size}&page=${page}${params.sort ? "&sort="+params.sort : ""}`;
      const response = await BaseAPI.put(url, {
        ...params,
      });
      if (response.status === 200) return response.data;
      return { error: "Falha ao consultar produtos mais vistos." };
    } catch (e) {
      return this._exception(e, "Falha ao consultar produtos mais vistos.");
    }
  }
}

export default SalesAPI;

import AuthoritiesModel from "./AuthoritiesModel";
import BaseModel from "./BaseModel";

class PerfilModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this["@class"] = "br.com.stoom.kernel.model.persistence.AuthorityGroups";
    this.name = data.name;
    this.hasCommission = data.hasCommission;
    this.commissionPercentage = data.commissionPercentage;
    this.active = data.active || false;
    this.uuid = data.uuid;
    this.authorities = data.authorities
      ? data.authorities.map((value) => new AuthoritiesModel(value))
      : [];
  }
}

export default PerfilModel;

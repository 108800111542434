import { extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import PropertiesAPI from "../services/PropertiesAPI";
import PropertiesModel from "../models/PropertiesModel";

class PropertiesStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      propertie: [],
      loading: false,
      superAdmin: false,
      properties: [],
      totalPages: 0,
      propertyAddList: [],
      propertyRemoveList: [],
      propertieSelected: [],
      size: 15,
      number: 0,
      sort: "ordering,desc",
    });
  }

  reset() {
    this.propertie = [];
    this.totalPages = 0;
    this.size = 15;
    this.number = 0;
    this.sort = "ordering,desc";
    this.loading = false;
  }

  /**Atualiza uma propriedade da nova unidade */
  async onChange(prop, value) {
    const _propertie = this.propertie ? this.propertie : new PropertiesModel();
    switch (prop) {
      case "selectable":
        this.propertie.selectable = value.value;
        break;
      default:
        _propertie[prop] = value;
    }

    this.propertie = new PropertiesModel(_propertie);
  }

  /**Cria uma propriedade nova */
  async onCreate() {
    this.loading = true;
    this.propertie.merchant.uuid = this.rootStore.usersStore.userMerchant.uuid;
    const data = JSON.stringify(this.propertie);
    const response = await PropertiesAPI.save(data);
    this.loading = false;
    if (!response.error) {
      this.propertie = new PropertiesModel();
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Especificação criada com sucesso."
      );
      if (this.propertyAddList.length > 0) {
        this.propertyAddList.map(async (item) => {
          await PropertiesAPI.saveProperties(response.uuid, [item]);
        });
      }

      this.propertie = new PropertiesModel(response);
      this.propertyAddList = [];
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Busca todas as propriedades */
  async getList(size = 15, page = this.page, sort = this.sort) {
    this.loading = true;
    const response = await PropertiesAPI.getList({
      size,
      page,
      sort,
    });
    this.loading = false;
    if (response.error) return [];
    this.properties = response.content.map(
      (properties) => new PropertiesModel(properties)
    );
    this.totalPages = response.totalPages;
    this.page = response.number;
  }

  async getExpoxtList(
    size = 1000,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    const response = await PropertiesAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content.map((usr) => new PropertiesModel(usr));
  }

  /**
   * Busca todas as informações da propriedade.
   * @param  {string} uuid
   */
  async get(uuid) {
    this.loading = true;
    const response = await PropertiesAPI.get(uuid);
    this.loading = false;
    if (!response.error) {
      this.propertie = new PropertiesModel(response);
      return this.propertie;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
  }

  /**Deleta Tamanho */
  async delete(uuid) {
    this.loading = true;
    const response = await PropertiesAPI.delete(uuid);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Tamanho excluído com sucesso!"
      );
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Atualiza os dados do tamanho. */
  async onUpdate() {
    this.loading = true;
    const data = JSON.stringify(this.propertie);
    const response = await PropertiesAPI.update(this.propertie.uuid, data);

    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Informações atualizadas com sucesso!"
      );

      if (this.propertyAddList.length > 0) {
        await PropertiesAPI.saveProperties(
          this.propertie.uuid,
          this.propertyAddList
        );
      }

      if (this.propertyRemoveList.length > 0) {
        this.propertyRemoveList.map(async (item) => {
          await PropertiesAPI.deleteProperties(item.uuid);
        });
      }

      this.propertie = new PropertiesModel(response);
      this.propertyRemoveList = [];
      this.propertyAddList = [];
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  addValuePropertiesList(unit) {
    unit.propertyValue = unit.displayString;
    this.propertie.values.push(unit);
    this.propertyAddList.push(unit);
  }

  removeValuePropertiesList(unit) {
    const removeItem = this.propertie.values.filter(
      (item) => item.displayString !== unit.displayString
    );
    if (unit.uuid) {
      this.propertyRemoveList.push(unit);
    }
    this.propertie.values = removeItem;
  }

  async getPropertieListSelect(attribute) {
    const response = await PropertiesAPI.getList({
      size: 100,
      page: 0,
      sort: "ordering,desc",
    });

    if (response.error) {
      this.propertieSelected = [];
      return;
    }

    if (attribute) {
      const filterAttribute = response.content.filter(
        (item) => item.selectable === true
      );

      this.propertieSelected = filterAttribute.map((propertie) => ({
        value: propertie.uuid,
        label: propertie.className,
        item: propertie.values,
      }));
    } else {
      this.propertieSelected = response.content.map((propertie) => ({
        value: propertie.uuid,
        label: propertie.className,
        item: propertie.values,
      }));
    }
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, this.sort);
  }

  async setPage(numPage, size = 15, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = "created,desc";

    sort ? (this.sort = sort) : (this.sort = "created,desc");

    await this.getList();
    return true;
  }
}
export default PropertiesStore;

import BaseModel from "./BaseModel";
import { valueStatusConvert } from "~/helpers/utils/Functions";

export default class OrderStatusModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this["@class"] = "br.com.stoom.kernel.model.persistence.OrderStatus";
    this.idOrderStatus = data.idOrderStatus;
    this.statusName = valueStatusConvert(data.statusName);
    this.status_name = valueStatusConvert(data.statusName);
  }
}

import BaseAPI, { URLS } from "./BaseAPI";

const MerchantUuidDTO = {
  "@class": "br.com.stoom.kernel.model.dto.merchant.MerchantUuidDTO",
};

class PropertiesAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(URLS.PROPERTIES, params);
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar a lista de unidades" };
    } catch (e) {
      return { error: "Falha ao carregar a lista de unidades" };
    }
  }

  static async save(data) {
    const modifiedData = {
      ...JSON.parse(data),
      merchant: { ...JSON.parse(data).merchant, ...MerchantUuidDTO },
    };
    try {
      const response = await BaseAPI.post(URLS.PROPERTIES, modifiedData);
      return response.status === 201
        ? response.data
        : { error: "Falha ao salvar propriedade" };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : "Falha ao salvar propriedade",
      };
    }
  }

  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.PROPERTIES}/${uuid}`);
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar propriedade" };
    } catch (e) {
      return { error: "Falha ao carregar propriedade" };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(
        `/manager/properties/property/classes/${uuid}`
      );
      return response.status === 204
        ? response.data
        : { error: "Falha ao deletar propriedade" };
    } catch (e) {
      return { error: "Falha ao deletar propriedade" };
    }
  }

  static async update(uuid, data) {
    const modifiedData = {
      ...JSON.parse(data),
      merchant: { ...JSON.parse(data).merchant, ...MerchantUuidDTO },
    };
    try {
      const response = await BaseAPI.put(
        `${URLS.PROPERTIES}/${uuid}`,
        modifiedData
      );
      return response.status === 200
        ? response.data
        : { error: "Falha ao atualizar tamanho" };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : "Falha ao salvar tamanho",
      };
    }
  }

  static async saveProperties(uuid, data) {
    try {
      const response = await BaseAPI.post(
        `${URLS.PROPERTIES}/${uuid}/values`,
        data
      );
      return response.status === 200
        ? response.data
        : { error: "Falha ao atualizar tamanho" };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : "Falha ao salvar tamanho",
      };
    }
  }

    static async deleteProperties(uuid) {
    try {
      const response = await BaseAPI.delete(
        `/manager/properties/property/${uuid}`
      );
      return response.status === 200
        ? response.data
        : { error: 'Falha ao atualizar valor de especificação' };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : 'Falha ao salvar valor de especificação',
      };
    }
  }
}

export default PropertiesAPI;

import BaseAPI, { URLS } from "./BaseAPI";
import StorageUtil, { KEYS } from '~/helpers/utils/StorageUtil';

class CustomerAPI {
  /**
   * Criação de um novo usuário, envio
   * @param  {object} userData Dados do novo usuário à ser criado
   * @returns uuid ou mensagem de erro
   */
  static async createUser(userData) {
    try {
      const response = await BaseAPI.post(URLS.CUSTOMERS, userData);
      return response.status === 201
        ? response
        : { error: "Falha ao cadastrar usuario" };
    } catch (e) {
      if (e.response.status === 409) {
        return { error: "E-mail ou documento de usuário já cadastrado" }
      }
      return { error: e?.response?.data?.document
        ? e?.response?.data?.document
        : "Falha ao cadastrar usuário" };
    }
  }

  /**
   * Ativação de clientes inativos.
   * @param  {object} customer Dados do novo usuário que será ativado
   * @returns uuid ou mensagem de erro
   */
  static async activeClient(customer) {
    try {
      const url = `${URLS.CUSTOMERS}/active`;
      const response = await BaseAPI.put(url, customer);
      return response.status === 200
        ? response.data
        : { error: "Falha ao ativar cliente" };
    } catch (e) {
      return { error: "Falha ao ativar cliente" };
    }
  }

  /**
   * @param  {string} uuid Uuid do usuário que será editado
   * @param {object} data dados do usuário que será editado
   */
  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.CUSTOMERS}/${uuid}`, data);
      if (response.status === 200) return response.data;
      return { error: "Falha ao atualizar informações do usuário" };
    } catch (e) {
      return { error: e?.response?.data?.message
        ? e?.response?.data?.message
        : "Falha ao atualizar usuário" };
    }
  }

  /**
   * Busca um usuario ( admin ou customer )
   * @param  {string} userName
   */
  static async get(prop, value) {
    try {
      const url = `${URLS.CUSTOMERS}/${value}`;
      const params = { by: prop };
      const response = await BaseAPI.get(url, params);
      return response.data;
    } catch (e) {
      return { error: "Falha ao buscar usuário" };
    }
  }

  /**
   * @param  {string} uuid Uuid do usuário que será excluído
   */
  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.CUSTOMERS}/${uuid}`);
      if (response.status === 204) return true;
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : "Não foi possível excluir o administrador",
      };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : "Não foi possível excluir o administrador",
      };
    }
  }

  /**Monta uma query de acordo aos dados  */
  /**
   * @param  {object} prop
   * @param  {valor} value
   */
  static async getBy(prop, value) {
    try {
      const url = `${URLS.INSUMOS_HOLAMBRA}/${value}/`;
      const params = { by: prop };
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar usuário" };
    } catch (e) {
      return { error: "Falha ao buscar usuário" };
    }
  }

  static async getUpdateErp(uuid) {
    try {
      const url = `customers/${uuid}/update-erp`;
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response.data;
    } catch (e) { }
    return { error: "Falha ao sincronizar com ERP" };
  }

  /**
   * Busca lista de clientes
   * @param  {params} params parametros de paginação
   */
  static async getList(params) {
    try {
      const response = await BaseAPI.get(`manager/customers/search`, {
        ...params,
      });
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar usuários" };
    } catch (e) {
      return { error: "Falha ao buscar usuários" };
    }
  }

  /**
   * Busca lista de clientes pedentes
   * @param  {params} params parametros de paginação
   */
  static async getPending(params) {
    try {
      const url = `${URLS.CUSTOMERS}/pending`;
      const response = await BaseAPI.get(url, params);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar clientes pendentes" };
    } catch (e) {
      return { error: "Falha ao buscar usuários" };
    }
  }

  static async getAllCustomersType() {
    try {
      const url = `${URLS.CUSTOMERS}/types`;
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar tipos de cliente" };
    } catch (e) {
      return { error: "Falha ao buscar tipos de cliente" };
    }
  }

  /**Busca todos os endereços */
  static async getAllAddress(uuid) {
    try {
      const response = await BaseAPI.get(`customers/${uuid}/address`);
      if (response.status === 200) return response.data;
      return { error: "Falha ao buscar usuários" };
    } catch (e) {
      return { error: "Falha ao buscar usuários" };
    }
  }

  static async createByCSV(file) {
    try {
      const url = `${URLS.CUSTOMERS}/create-by-csv`;
      const form = new FormData();
      form.append('file', file);
      const data = form;
      const config = getConfig(file);
      const response = await BaseAPI.post(url, data, config);
      if (response.status === 200) return response.data;
    } catch (e) {
      console.log(e.message);
      return { error: e?.response?.data ?? "Error" };
    }
  }
}

const getConfig = (uploadItem) => ({
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${StorageUtil.getItem(KEYS.TOKEN_KEY)}`,
    'Content-Type': 'multipart/form-data',
  },
  onUploadProgress: (progressEvent) =>
  (uploadItem.percentCompleted = parseInt(
    Math.floor((progressEvent.loaded * 100) / progressEvent.total)
  )),
});


export default CustomerAPI;

import { extendObservable } from "mobx";
import { STATUS_HELPER } from "~/helpers/ToastHelper";
import ShippingClass from "../models/ShippingClass";
import ShippingCompany from "../models/ShippingCompany";
import ShippingClassAPI from "../services/ShippingClassAPI";

const initValues = {
  loading: false,
  shippingClass: undefined,
  shippingsClass: [],
  shippingsClassSelect: [],
};

class ShippingClassStore {
  totalPages = 0;
  page = 0;
  size = 10;
  sort = "modified,desc";
  filter = "";

  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, { ...initValues });
  }

  reset() {
    this.shippingsClass = [];
    this.shippingsClassSelect = [];
    this.shippingClass = undefined;
    this.totalPages = 0;
    this.page = 0;
    this.sort = "modified,desc";
    this.size = 10;
  }

  /**Notificações. */
  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  /**Cria novo Shipping Service */
  createClass() {
    this.shippingClass = new ShippingCompany();
  }

  /**Busca Transportadoras */
  async getListClass() {
    this.loading = true;
    const response = await ShippingClassAPI.getListClass({
      page: this.page,
      size: this.size,
      sort: this.sort,
      name: this.filter,
    });
    if (!response.error) {
      this.shippingsClass = response.content.map((s) => new ShippingCompany(s));

      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.notificationStore.notifyError(response.error);
    this.loading = false;
    return response;
  }

  /** Get Shipping Service */
  async getClass(uuid) {
    this.loading = true;
    const response = await ShippingClassAPI.getClass(uuid);
    if (!response.error) this.shippingClass = new ShippingClass(response);
    else this.notificationStore.notifyError(response.error);
    this.loading = false;
    return response;
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getListClass();
  }

  async delete(uuid) {
    this.loading = true;
    const response = await ShippingClassAPI.delete(uuid);

    if (!response.error)
      this.notificationStore.notify(
        STATUS_HELPER.INFO,
        "Transportadora excluída com sucesso"
      );
    else this.notificationStore.notifyError(response.error);

    this.loading = false;

    return response;
  }

  /**Retorna lista de categorias para uso no select */
  getListSelect(shipping = this.shippingsClass) {
    return shipping.map((shipping) => ({
      value: shipping.uuid,
      label: shipping.name,
    }));
  }

  async getShippingsClassSelect() {
    this.loading = true;

    const response = await ShippingClassAPI.getAll();

    if (!response.error) {
      this.shippingsClassSelect = response.map((s) => {
        return {
          shippingClass: new ShippingCompany(s),
          value: s.uuid,
          label: s.name,
        };
      });
    } else this.notificationStore.notifyError(response.error);

    this.loading = false;

    return response;
  }

  async searchByName(inputValue, size = 10) {
    this.page = 0;
    this.sort = "modified,desc";
    this.filter = inputValue;
    this.size = size;
    await this.getListClass();
    return this.getListSelect();
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 10, sort) {
    this.page = numPage;
    this.size = size;

    if (!this.sort) {
      this.sort = "modified,desc";
    }

    if (sort) {
      this.sort = sort;
    }

    return await this.getListClass();
  }

  async getExpoxtList(
    size = 1000,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    const response = await ShippingClassAPI.getListClass({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }
    return response.content.map((value) => new ShippingCompany(value));
  }
}

export default ShippingClassStore;

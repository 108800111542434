import BaseModel from "./BaseModel";
class WithdrawlsModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.weekDay = data.weekDay;
    this.initialHour = data.initialHour;
    this.finalHour = data.finalHour;
    this.maxOrders = data.maxOrders;
  }
}

export default WithdrawlsModel;

import { extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "../helpers/ToastHelper";
import RolesModel from "../models/RolesModel";
import RolesAPI from "../services/RolesAPI";

const initValues = {
  loading: false,
  role: undefined,
  rolesList: [],
  totalPages: 0,
  size: 20,
  page: 0,
  sort: "created,desc",
  defaultAuth: [],
};

class RolesStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, { ...initValues });
  }

  reset() {
    this.totalPages = 0;
    this.page = 0;
    this.size = 10;
    this.sort = "created,desc";
    this.role = new RolesModel();
    this.loading = false;
    this.rolesList = [];
    this.defaultAuth = [];
  }

  async getRolesByUuid(uuid) {
    this.loading = true;
    const response = await RolesAPI.getRolesByUuid(uuid);
    this.loading = false;

    if (!response.error) {
      this.defaultAuth = response.authorityGroups.map((item) => ({
        value: item.uuid,
        label: item.name,
      }));
      this.role = new RolesModel(response);
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }

    return response;
  }

  async getAllRoles(size = 15, page = this.page, sort = this.sort, filters = this.filters) {
    this.loading = true;
    const response = await RolesAPI.getAllRoles({
      size,
      page,
      sort,
      ...filters,
    });
    this.loading = false;
    if (response.error) return [];
    this.rolesList = response.content.map((usr) => new RolesModel(usr));
  }

  async setPage(numPage, size = 20, sort) {
    this.page = numPage;
    this.size = size;
    this.sort = "name";
    sort ? (this.sort = sort) : (this.sort = "name");

    await this.getAllRoles(this.size, this.page, this.sort);
    return true;
  }

  async getExpoxtList(
    size = 999999,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;
    const response = await RolesAPI.getAllRoles({
      size,
      page,
      sort,
      ...filters,
    });
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      return [];
    }
    this.loading = false;
    return response.content.map((usr) => new RolesModel(usr));
  }

  async updateRoles(uuid) {
    this.loading = true;
    
    this.role.authorityGroups.map((item) => item.authorities = []);

    const response = await RolesAPI.updateRoles(this.role, uuid);

    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Alterações gravadas com sucesso."
      );
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  async createRoles() {
    this.loading = true;

    this.role.authorityGroups.map((item) => item.authorities = []);

    const response = await RolesAPI.createRoles(this.role);

    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Salvo com sucesso."
      );
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  async deleteRoles(uuid) {
    this.loading = true;

    const response = await RolesAPI.deleteRoles(uuid);

    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Deletado com sucesso."
      );
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    this.getAllRoles();
    return response;
  }

  async updatePropRoles(prop, value) {
    const roleCopy = this.role
      ? this.role
      : new RolesModel();
    switch (prop) {
      case "authorityGroups":
        if (value.action === "select-option") {
          const newAuthority1 = { uuid: value.option.value }
          roleCopy.authorityGroups = roleCopy.authorityGroups ? [...roleCopy.authorityGroups, newAuthority1] : [newAuthority1]
        } else if (value.action === "remove-value") {
          roleCopy.authorityGroups = roleCopy.authorityGroups.filter((item) => item.uuid !== value.removedValue.value)
        }
        break;
      case "hierarchy":
        roleCopy[prop] = parseInt(value);
        break;
      default:
        roleCopy[prop] = value;
    }
    this.role = new RolesModel(roleCopy);
  }
}
export default RolesStore;

import BaseAPI, { URLS } from "./BaseAPI";

class StoreSetupAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(URLS.STAMP, params);
      return response.status === 200
        ? response.data.result
        : { error: "Falha ao carregar" };
    } catch (e) {
      return { error: "Falha ao carregar" };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.STAMP, data);
      return response.status === 201
        ? response.data
        : { error: "Falha ao salvar" };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : "Falha ao salvar",
      };
    }
  }

  static async update(data) {
    try {
      const response = await BaseAPI.post(`${URLS.STAMP}`, data);
      return response.status === 200
        ? response.data
        : { error: "Falha ao atualizar" };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : "Falha ao salvar Selo",
      };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.STAMP}/${uuid}`);
      return response.status === 204
        ? response.data
        : { error: "Falha ao deletar" };
    } catch (e) {
      return { error: "Falha ao deletar" };
    }
  }
}

export default StoreSetupAPI;

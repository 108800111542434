import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./pages/App/App";
import { createRoot } from "react-dom/client";
import "./styles/build/css/global.css";

const root = createRoot(document.getElementById("root"));

root.render(
  <Router>
    <App />
  </Router>
);

import { extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import StoreSetupModel from "../models/StoreSetupModel";
import TopBarContentsModel from "../models/TopBarContentsModel";
import StoreSetupAPI from "../services/StoreSetupAPI";
import UploadAPI from "../services/UploadAPI";

class StoreSetupStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      unit: undefined,
      loading: false,
      storeSetup: new StoreSetupModel(),
      imageHeaderToAdd: null,
      imageHeaderToDelete: null,
      favIconeaderToAdd: null,
      favIconHeaderToDelete: null,
      topBarListWeb: [],
      topBarListResponsive: [],
    });
  }

  reset() {
    this.unit = [];
    this.loading = false;
    this.favIconHeaderToAdd = null;
    this.favIconHeaderToDelete = null;
  }

  async get() {
    this.loading = true;
    const response = await StoreSetupAPI.get();
    this.loading = false;
    if (response.error) return [];
    this.storeSetup = new StoreSetupModel(response);
    this.topBarListWeb = this.storeSetup.topBarContents;
    this.topBarListResponsive = this.storeSetup.topBarContents;
  }

  /**Atualiza um novo registror*/
  onChange(prop, value) {
    switch (prop) {
      default:
        this.storeSetup[prop] = value;
    }
  }

  onChangeTypePayment(prop, value) {
    const _storeSetup = this.storeSetup
      ? this.storeSetup
      : new StoreSetupModel();
    switch (prop) {
      default:
        _storeSetup[prop] = value;
    }
    this.storeSetup = new StoreSetupModel(_storeSetup);
  }

  async handleCreateTopBar(value) {
    this.loading = true;

    const topbar = new TopBarContentsModel();
    topbar.uuid = value.uuid;
    topbar.position = value.position.label;
    topbar.mobile = value.mobile;
    topbar.link = value.link;
    topbar.ordination = value.ordination;
    topbar.name = value.name;

    this.storeSetup.topBarContents.push(topbar);
    this.topBarListWeb.push(topbar);
    this.topBarListResponsive.push(topbar);
    this.loading = false;
  }

  async handleDeleteTopBar(uuid) {
    this.loading = true;

    this.storeSetup.topBarContents = this.storeSetup.topBarContents.filter(
      (topBarContent) => topBarContent.uuid !== uuid
    );

    this.loading = false;
  }

  handleUploadImageHeader(file) {
    this.loading = true;
    this.imageHeaderToAdd = file;
    this.loading = false;
    this.toastHelper.notify(
      STATUS_HELPER.SUCCESS,
      "Imagem cadastrada com sucesso!"
    );
  }

  handleUploadFavIconHeader(file) {
    this.loading = true;
    this.favIconHeaderToAdd = file;

    this.toastHelper.notify(
      STATUS_HELPER.SUCCESS,
      "Imagem cadastrada com sucesso!"
    );
    this.loading = false;
  }

  async sendFiles() {
    this.imageHeaderToAdd &&
      (await UploadAPI.uploadImageHeaderStoreSetup(this.imageHeaderToAdd));
    this.favIconHeaderToAdd &&
      (await UploadAPI.uploadFavIconHeaderStoreSetup(this.favIconHeaderToAdd));
  }

  async onUpdate() {
    this.loading = true;

    const data = JSON.stringify(this.storeSetup);
    const response = await StoreSetupAPI.update(data);
    if (!response.error) {
      await this.sendFiles();
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        "Informações atualizadas com sucesso!"
      );

      const responseGet = await StoreSetupAPI.get();
      if (responseGet.error) return [];
      this.storeSetup = new StoreSetupModel(responseGet);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  async delete(uuid) {
    this.loading = true;
    const response = await StoreSetupAPI.delete(uuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, "Excluído com sucesso!");
      this.getList();
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }
}
export default StoreSetupStore;

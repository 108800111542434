class TemplateModel {
  constructor(data = {}) {
    this.active = data.active;
    this.body = data.body;
    this.created = data.created;
    this.deleted = data.deleted;
    this.modified = data.modified;
    this.subject = data.subject;
    this.type = data.type;
    this.uuid = data.uuid;
  }
}

export default TemplateModel;


import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import LoadingComponent from "../src/components/LoadingComponent/LoadingComponent";
import StorageUtil, { KEYS } from "./helpers/utils/StorageUtil";
import { useStores } from "~/hooks/useStores";
import LoadingAllScreenComponent from "./components/LoadingAllScreenComponent/LoadingAllScreenComponent";

//Imports apenas importados caso forem requisitados pela aplicação.
const HomePage = lazy(() => import("./pages/HomePage/HomePage"));
const LoginCenterPage = lazy(() =>
  import("./pages/LoginCenterPage/LoginCenterPage")
);

// Clientes
const ManagerCustomerContainer = lazy(() =>
  import("~/containers/ManagerCustomerContainer")
);

const ManagerUserContainer = lazy(() =>
  import("~/containers/ManagerUserContainer")
);

const CustomerContainer = lazy(() => import("~/containers/CustomerContainer"));

const ManagerCustomerMassInclusionContainer = lazy(() => import("~/containers/ManagerCustomerMassInclusionContainer"))

// // Perfil
const ManagerPerfilContainer = lazy(() =>
  import("~/containers/ManagerPerfilContainer")
);

const UserContainer = lazy(() => import("~/containers/UserContainer"));
const PerfilContainer = lazy(() => import("~/containers/PerfilContainer"));

// // Filiais
const ManagerBranchesContainer = lazy(() =>
  import("~/containers/ManagerBranchesContainer")
);

const BranchesContainer = lazy(() => import("~/containers/BranchesContainer"));

// Home Dinamica
const ManagerDynamicHomeContainer = lazy(() =>
  import("~/containers/ManagerDynamicHomeContainer")
);

const DynamicHomeContainer = lazy(() =>
  import("~/containers/DynamicHomeContainer")
);

const EditHomeComponentContainer = lazy(() =>
  import("~/containers/EditHomeComponentContainer")
);

// Banner
const ManagerBannersContainer = lazy(() =>
  import("~/containers/ManagerBannersContainer")
);

const BannerContainer = lazy(() => import("~/containers/BannerContainer"));

// Categorias
const ManagerCategoryContainer = lazy(() =>
  import("~/containers/ManagerCategoryContainer")
);
const CategoryContainer = lazy(() => import("~/containers/CategoryContainer"));

//Pedidos
const ManagerOrderContainer = lazy(() =>
  import("~/containers/ManagerOrderContainer")
);

const ViewOrderContainer = lazy(() =>
  import("~/containers/OrderContainer/ViewOrderContainer")
);

// //Produtos
const ManagerProductCommentsContainer = lazy(() =>
  import("~/containers/ManagerProductCommentsContainer")
);

const ManagerProductsContainer = lazy(() =>
  import("~/containers/ManagerProductsContainer")
);

const ProductVariationContainer = lazy(() =>
  import("~/containers/ProductVariationContainer")
);

const ViewProductVariationContainer = lazy(() =>
  import("~/containers/ProductVariationContainer/View/Product")
);

const ProductContainer = lazy(() => import("~/containers/ProductContainer"));
const ViewProductContainer = lazy(() =>
  import("~/containers/ProductContainer/View/Product")
);

const ManagerProductMassInclusionContainer = lazy(() =>
  import("~/containers/ManagerProductMassInclusionContainer")
);

const ManagerProductMassUpdateContainer = lazy(() =>
  import("~/containers/ManagerProductMassUpdateContainer")
);

const ManagerPriceListMassInclusionContainer = lazy(() =>
  import("~/containers/ManagerPriceListMassInclusionContainer")
);

//Atributos
const ManagerColorContainer = lazy(() =>
  import("~/containers/ManagerColorContainer")
);
const ColorContainer = lazy(() => import("~/containers/ColorContainer"));

const ManagerSizeContainer = lazy(() =>
  import("~/containers/ManagerSizeContainer")
);
const SizeContainer = lazy(() => import("~/containers/SizeContainer"));

const ManagerVoltagemContainer = lazy(() =>
  import("~/containers/ManagerVoltagemContainer")
);

const VoltagemContainer = lazy(() => import("~/containers/VoltagemContainer"));

const ManagerMaterialContainer = lazy(() =>
  import("~/containers/ManagerMaterialContainer")
);

const MaterialContainer = lazy(() => import("~/containers/MaterialContainer"));

//Unidades
const UnitContainer = lazy(() => import("~/containers/UnitContainer"));
const ManagerUnitsContainer = lazy(() =>
  import("~/containers/ManagerUnitsContainer")
);

// //Campanha
const ManagerCampaignContainer = lazy(() =>
  import("~/containers/ManagerCampaignContainer")
);

const CampaignContainer = lazy(() => import("~/containers/CampaignContainer"));

//Merchant
const MerchantContainer = lazy(() => import("~/containers/MerchantContainer"));

const ManagerMerchantContainer = lazy(() =>
  import("~/containers/ManagerMerchantContainer")
);

//Relatorios
const ManagerSalesContainer = lazy(() =>
  import("~/containers/ManagerSalesContainer")
);

const ManagerReportProductContainer = lazy(() =>
  import("~/containers/ManagerReportProductContainer")
);

const ManagerReportProductEvaluationContainer = lazy(() =>
  import("~/containers/ManagerReportProductEvaluationContainer")
);

const ManagerReportAbandonedCartContainer = lazy(() =>
  import("~/containers/ManagerReportAbandonedCartContainer")
);

const ManagerReportProductMostViewdContainer = lazy(() =>
  import("~/containers/ManagerReportProductMostViewdContainer")
);

const ManagerReportCategorySales = lazy(() =>
  import("~/containers/ManagerReportCategorySales")
);

const ManagerReportCampaing = lazy(() =>
  import("~/containers/ManagerReportCampaing")
);

const ManagerReportCupom = lazy(() =>
  import("~/containers/ManagerReportCupom")
);

const ManagerReportLog = lazy(() => import("~/containers/ManagerReportLog"));

// //Newlstter
const ManagerNewsLetterContainer = lazy(() =>
  import("~/containers/ManagerNewsLetterContainer")
);

const NewsLetterContainer = lazy(() =>
  import("~/containers/NewsLetterContainer")
);

// //Cupom
const ManagerCouponContainer = lazy(() =>
  import("~/containers/ManagerCouponContainer")
);

const CouponContainer = lazy(() => import("~/containers/CouponContainer"));

// //Trasportadora
const ManagerShippingClassContainer = lazy(() =>
  import("~/containers/ManagerShippingClassContainer")
);

const ShippingClassContainer = lazy(() =>
  import("~/containers/ShippingClassContainer")
);

// //ZipCode
const ZipCodeRangeContainer = lazy(() =>
  import("~/containers/ZipCodeRangeContainer")
);

const ManagerZipCodeRangeContainer = lazy(() =>
  import("~/containers/ManagerZipCodeRangerContainer")
);

//Properties
const ManagerPropertiesContainer = lazy(() =>
  import("~/containers/ManagerPropertiesContainer")
);
const PropertiesContainer = lazy(() =>
  import("~/containers/PropertiesContainer")
);

//Dashboard
const ManagerDashboardContainer = lazy(() =>
  import("~/containers/ManagerDashboardContainer")
);

//TemplateEmail
const TemplateEmailContainer = lazy(() =>
  import("~/containers/TemplateEmailContainer")
);

const ManegerTemplatesEmailContainer = lazy(() =>
  import("~/containers/ManegerTemplatesEmailContainer")
);

//Configuração da loja
const ManagerStoreSetup = lazy(() => import("~/containers/ManagerStoreSetup"));

//Configuração da loja
const ManagerInstitutional = lazy(() =>
  import("~/containers/ManagerInstitutional")
);
const InstitutionalContainer = lazy(() =>
  import("~/containers/InstitutionalContainer")
);

//Condição de Pagamento
const InstallmentsCondition = lazy(() =>
  import("~/containers/InstallmentsCondition")
);

//Cargos
const ManagerRolesContainer = lazy(() =>
  import("~/containers/ManagerRolesContainer")
);

const RolesContainer = lazy(() =>
  import("~/containers/RolesContainer")
);

//Rotas globais de nossa aplicação manager.
export default inject(({ authStore }) => ({ authStore }))(
  withRouter(
    observer((props) => {
      //função logout
      const logout = () => {
        props.authStore.logout().then(() => props.history.push("/"));
      };

      return (
        <Suspense fallback={<LoadingComponent />}>
          <Switch>
            <Route exact path="/login" render={() => <Redirect to={"/"} />} />
            <Route exact path="/logout" render={() => logout()} />

            <PrivateRoute
              path="/"
              component={HomePage}
              isAuthenticated={props.authStore.isAuthenticated}
            />
            <Redirect to="/error-page" />
          </Switch>
        </Suspense>
      );
    })
  )
);

export const HomeRoutes = () => (
  <Switch>
    {/* Clientes - */}
    <Route exact path="/clientes" component={ManagerCustomerContainer} />
    <Route
      exact
      path="/cadastrar-clientes-novo"
      component={CustomerContainer}
    />
    <Route
      exact
      path="/editar-cliente/:uuidCustomer"
      component={CustomerContainer}
    />

    <Route
      exact
      path="/importar-clientes-em-massa"
      component={ManagerCustomerMassInclusionContainer}
    />

    {/* Filiais */}
    <Route exact path="/cadastrar-filial" component={BranchesContainer} />
    <Route
      exact
      path="/editar-filial/:uuidBranch"
      component={BranchesContainer}
    />
    <Route exact path="/filiais" component={ManagerBranchesContainer} />

    {/* Banners */}
    <Route exact path="/cadastrar-banner" component={BannerContainer} />
    <Route
      exact
      path="/editar-banner/:bannerUuid"
      component={BannerContainer}
    />
    <Route exact path="/banners" component={ManagerBannersContainer} />

    {/* Merchant - */}
    <Route exact path="/cadastrar-merchant" component={MerchantContainer} />
    <Route exact path="/merchants" component={ManagerMerchantContainer} />
    <Route
      exact
      path="/editar-merchant/:uuidMerchant"
      component={MerchantContainer}
    />

    {/* Unidades - */}
    <Route exact path="/unidades" component={ManagerUnitsContainer} />
    <Route exact path="/cadastrar-unidade" component={UnitContainer} />
    <Route exact path="/editar-unidade/:uuidUnit" component={UnitContainer} />

    {/* Relatorios - */}
    <Route exact path="/vendas/general" component={ManagerSalesContainer} />
    <Route
      exact
      path="/vendas/produto"
      component={ManagerReportProductContainer}
    />
    <Route
      exact
      path="/vendas/avaliacao-produtos"
      component={ManagerReportProductEvaluationContainer}
    />
    <Route
      exact
      path="/vendas/carrinho-abandonado"
      component={ManagerReportAbandonedCartContainer}
    />
    <Route
      exact
      path="/vendas/produtos-mais-vistos"
      component={ManagerReportProductMostViewdContainer}
    />
    <Route
      exact
      path="/vendas/categorias"
      component={ManagerReportCategorySales}
    />
    <Route exact path="/vendas/campanha" component={ManagerReportCampaing} />
    <Route exact path="/vendas/cupom" component={ManagerReportCupom} />
    <Route exact path="/vendas/logs" component={ManagerReportLog} />

    {/* Perfil */}
    <Route path="/lista-perfil" component={ManagerPerfilContainer} />
    <Route path="/novo-perfil" component={PerfilContainer} />
    <Route path="/editar-perfil/:perfilUuid" component={PerfilContainer} />

    {/* Usuário */}
    <Route exact path="/lista-usuarios" component={ManagerUserContainer} />
    <Route exact path="/editar-usuario/:uuidUnit" component={UserContainer} />
    <Route exact path="/cadastrar-usuario" component={UserContainer} />

    {/* Atributos */}
    <Route exact path="/cores" component={ManagerColorContainer} />
    <Route exact path="/cadastrar-cor" component={ColorContainer} />
    <Route exact path="/editar-cor/:uuidColor" component={ColorContainer} />

    <Route exact path="/tamanhos" component={ManagerSizeContainer} />
    <Route exact path="/cadastrar-tamanho" component={SizeContainer} />
    <Route exact path="/editar-tamanho/:uuidSize" component={SizeContainer} />

    <Route exact path="/material" component={ManagerMaterialContainer} />
    <Route exact path="/cadastrar-material" component={MaterialContainer} />
    <Route
      exact
      path="/editar-material/:uuidMaterial"
      component={MaterialContainer}
    />

    {/* Categorias */}
    <Route exact path="/categorias" component={ManagerCategoryContainer} />
    <Route exact path="/cadastrar-categoria" component={CategoryContainer} />
    <Route
      exact
      path="/editar-categoria/:uuidCategory"
      component={CategoryContainer}
    />

    {/* Propriedades - */}
    <Route exact path="/propriedades" component={ManagerPropertiesContainer} />
    <Route
      exact
      path="/cadastrar-propriedade"
      component={PropertiesContainer}
    />

    <Route
      exact
      path="/editar-propriedade/:uuidProperties"
      component={PropertiesContainer}
    />

    {/* Transportadora - */}
    <Route
      exact
      path="/transportadoras"
      component={ManagerShippingClassContainer}
    />
    <Route
      exact
      path="/cadastrar-transportadora"
      component={ShippingClassContainer}
    />
    <Route
      exact
      path="/editar-transportadora/:uuidShipping"
      component={ShippingClassContainer}
    />

    {/* Pedidos */}
    <Route exact path="/pedidos" component={ManagerOrderContainer} />

    <Route
      exact
      path="/visualizar-pedido/:uuidOrder/customers/:customerUuid"
      component={ViewOrderContainer}
    />

    {/* Produtos */}
    <Route exact path="/produtos" component={ManagerProductsContainer} />
    <Route
      exact
      path="/gerenciar-comentarios"
      component={ManagerProductCommentsContainer}
    />

    <Route exact path="/cadastrar-produtos" component={ProductContainer} />
    <Route
      exact
      path="/visualizar-produto/:uuidProduct"
      component={ViewProductContainer}
    />
    <Route
      exact
      path="/editar-produto/:uuidProduct"
      component={ProductContainer}
    />
    <Route
      exact
      path="/cadastrar-variacao-produtos"
      component={ProductVariationContainer}
    />

    <Route
      exact
      path="/editar-variacao-produto/:uuidProduct"
      component={ProductVariationContainer}
    />
    <Route
      exact
      path="/visualizar-variacao-produto/:uuidProduct"
      component={ViewProductVariationContainer}
    />
    <Route
      exact
      path="/inclusao-produtos-em-massa"
      component={ManagerProductMassInclusionContainer}
    />
    <Route
      exact
      path="/edicao-produtos-em-massa"
      component={ManagerProductMassUpdateContainer}
    />
    <Route
      exact
      path="/inclusao-preco-em-massa"
      component={ManagerPriceListMassInclusionContainer}
    />

    {/* ZipCode - */}
    <Route
      exact
      path="/faixas-frete"
      component={ManagerZipCodeRangeContainer}
    />
    <Route
      exact
      path="/cadastrar-faixa-frete"
      component={ZipCodeRangeContainer}
    />
    <Route
      exact
      path="/editar-faixa-frete/:uuidZipCodeRange"
      component={ZipCodeRangeContainer}
    />

    {/* Cupom - */}
    <Route exact path="/cupons" component={ManagerCouponContainer} />
    <Route exact path="/cadastrar-cupom" component={CouponContainer} />
    <Route exact path="/editar-cupom/:couponUuid" component={CouponContainer} />

    {/* Home Dinamica */}
    {/* <Route
      exact
      path="/home-dinamica"
      component={ManagerDynamicHomeContainer}
    /> */}
    <Route
      exact
      path="/editar-component/:branchUuid/filial/:filialUuid"
      component={EditHomeComponentContainer}
    />
    <Route exact path="/home-dinamica" component={DynamicHomeContainer} />
    <Route exact path="/home-dinamica" component={DynamicHomeContainer} />

    {/* Campanhas - */}
    <Route exact path="/campanhas" component={ManagerCampaignContainer} />
    <Route exact path="/cadastrar-campanha" component={CampaignContainer} />
    <Route
      exact
      path="/editar-campanha/:campaignUuid"
      component={CampaignContainer}
    />

    {/* Newsletters - */}
    <Route exact path="/newsletters" component={ManagerNewsLetterContainer} />
    <Route path="/newsletter" component={NewsLetterContainer} />
    <Route
      path="/editar-newsletter/:newsletterUuid"
      component={NewsLetterContainer}
    />

    {/* Dashboard - */}
    <Route exact path="/" component={ManagerDashboardContainer} />

    {/* Templates de e-mail */}
    <Route
      exact
      path="/lista-templates"
      component={ManegerTemplatesEmailContainer}
    />
    <Route
      exact
      path="/cadastrar-template"
      component={TemplateEmailContainer}
    />
    <Route
      exact
      path="/editar-template/:uuidUnit"
      component={TemplateEmailContainer}
    />

    {/* Configurações da loja */}
    <Route exact path="/configuracoes-loja" component={ManagerStoreSetup} />
    <Route exact path="/institucional" component={ManagerInstitutional} />
    <Route
      exact
      path="/cadastrar-institucional"
      component={InstitutionalContainer}
    />
    <Route
      exact
      path="/editar-institucional/:uuidInstitucional"
      component={InstitutionalContainer}
    />

    {/* Voltagem */}
    <Route exact path="/voltagem" component={ManagerVoltagemContainer} />
    <Route exact path="/cadastrar-voltagem" component={VoltagemContainer} />
    <Route
      exact
      path="/editar-voltagem/:uuidVoltagem"
      component={VoltagemContainer}
    />

    {/* Condição de pagamento */}
    <Route
      exact
      path="/condicoes-parcelamento"
      component={InstallmentsCondition}
    />

    {/* Cargos */}
    <Route
      exact
      path="/cargos"
      component={ManagerRolesContainer}
    />
    <Route
      exact
      path="/cadastrar-cargo"
      component={RolesContainer}
    />
    <Route
      exact
      path="/editar-cargo/:roleUuid"
      component={RolesContainer}
    />
  </Switch>
);

/*Private route é responsável por verificar se o usuário está logado ou não. */
const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  const { usersStore } = useStores();
  const { loadingUser } = usersStore;
  const token = StorageUtil.getItem(KEYS.TOKEN_KEY);

  return (
    <Route
      {...rest}
      render={(props) =>
        token !== null ? (
          <>
            {!loadingUser && <Component {...props} />}
            {loadingUser && <LoadingAllScreenComponent />}
          </>
        ) : (
          <LoginCenterPage {...props} />
        )
      }
    />
  );
};

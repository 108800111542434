import { extendObservable } from "mobx";
import ToastHelper, { STATUS_HELPER } from "~/helpers/ToastHelper";
import { removeSpecialCharacters } from "~/helpers/utils/Functions";
import { entityTypes } from "~/helpers/utils/Selects";
import { formatarDatacustomer } from "../helpers/utils/Functions";
import AddressModel from "../models/AddressModel";
import CustomerModel from "../models/CustomerModel";
import CustomerAPI from "../services/CustomerAPI";
import AdministratorModel from "../models/AdministratorModel";
import AdministradorAPI from "../services/AdministratorAPI";
class CustomerStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      customer: new CustomerModel(),
      customers: [],
      loading: false,
      totalPages: 0,
      addressList: [],
      size: 15,
      loadingexport: false,
      page: 0,
      filters: {},
      sort: "created,desc",
      massImportResult: [],
      parents: [],
    });
  }

  reset() {
    this.customer = undefined;
    this.totalPages = 0;
    this.size = 15;
    this.page = 0;
    this.sort = "created,desc";
    this.massImportResult = [];
    this.parents = [];
  }

  initializeCustomer() {
    this.customer = new CustomerModel();
  }

  /**Mensagem genérica. */
  showMessage(message) {
    this.toastHelper.notify(STATUS_HELPER.INFO, message);
  }

  /**Busca lista de clientes */
  async getList(
    size = 15,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;
    const response = await CustomerAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }

    this.customers = response.content.map((usr) => new CustomerModel(usr));
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.loading = false;
  }

  async setFilters(filters) {
    this.page = 0;
    this.filters = filters;
    await this.getList(this.size, this.page, this.sort, filters);
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 15, isPending = false, sort) {
    this.page = numPage;
    this.size = size;
    sort && (this.sort = sort);
    !isPending ? await this.getList() : await this.getPending();
    return true;
  }

  /**Delete */
  async delete(uuid) {
    this.loading = true;
    const response = await CustomerAPI.delete(uuid);
    this.loading = false;
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Usuário deletado com sucesso"
      );
      return response;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, this.sort);
  }

  /**Cria um novo usuário (cliente) */
  async onCreate() {
    this.loading = true;
    this.customer["email"] = this.customer.user.email;
    this.customer.user.username = this.customer.user.email;
    delete this.customer.u;
    delete this.customer.first_name;

    if (this.customer.birthdate) {
      this.customer.birthdate = formatarDatacustomer(this.customer.birthdate);
    }

    if (this.customer.mobile) {
      this.customer.mobile = removeSpecialCharacters(this.customer.mobile);
    }

    if (this.customer.entity === "FISICA") {
      this.customer.birthdate = this.customer.birthdate
        .split("/")
        .reverse()
        .join("-");
    }
    this.customer["newsletter"] = false;

    const data = JSON.stringify(this.customer);
    const response = await CustomerAPI.createUser(data);

    if (!response.error) {
      this.customer = new CustomerModel({ entity: entityTypes[0].value });
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Usuário criado com sucesso."
      );
    } else {
      if (response.error === 'Invalid document for entity JURIDICA') {
        this.toastHelper.notify(STATUS_HELPER.ERROR, "Documento inválido para Pessoa Jurídica");
      } else if (response.error === 'Invalid document for entity FISICA') { 
        this.toastHelper.notify(STATUS_HELPER.ERROR, "Documento inválido para Pessoa Física");
      } else {
        this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      }
    }
    this.loading = false;
    return response;
  }

  /**Atualiza uma propriedade do novo adminstrator*/
  async onChange(prop, value) {
    if (!this.customer) {
      this.customer = new CustomerModel();
    }
    const customer = this.customer;
    switch (prop) {
      case "email":
        customer.user.email = value;
        break;
      case "mobile":
        customer.mobile = value;
        break;
      case "entity":
        customer.entity = value.value;
        break;
      case "gender":
        customer.gender = value.value;
        break;
      case "pass":
        customer.user.password = value;
        break;
      case "birthdate":
        customer.birthdate = value;
        break;

      case "exempt":
        value === true
          ? (customer.stateRegistration = "ISENTO")
          : (customer.stateRegistration = undefined);
        customer[prop] = value;
        break;
      case "parentAdministrator":
        customer[prop] = value ? { uuid: value.value } : null
        break;
      default:
        customer[prop] = value;
    }

    this.customer = new CustomerModel(customer);
  }

  /**
   * *Busca todas as informações de um usuario customer
   * @param  {} userName
   */
  async get(userName) {
    this.loading = true;
    const response = await CustomerAPI.get("uuid", userName);
    const result = await CustomerAPI.getAllAddress(userName);

    if (!response.error) {
      this.customer = new CustomerModel(response);
      if (!result.error) {
        this.addressList = result.map((value) => new AddressModel(value));
      }
      this.loading = false;
      return this.customer;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Atualiza informações do cliente */
  async update() {
    this.loading = true;
    delete this.customer.u;
    delete this.customer.first_name;
    this.customer["email"] = this.customer.user.email;

    if (this.customer.birthdate) {
      this.customer.birthdate = formatarDatacustomer(this.customer.birthdate);
    }

    if (this.customer.mobile) {
      this.customer.mobile = removeSpecialCharacters(this.customer.mobile);
    }

    // this.customer.user.uuid = 'br.com.stoom.partage.model.PartageCustomer';
    const customer = { ...this.customer };
    delete customer.uuid;
    const data = JSON.stringify(customer);
    const response = await CustomerAPI.update(this.customer.uuid, data);
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.INFO,
        "Informações atualizadas com sucesso."
      );
    } else if (response.error === ' Invalid document for entity JURIDICA') {
      this.toastHelper.notify(STATUS_HELPER.ERROR, "Documento inválido para Pessoa Jurídica");
    } else if (response.error === ' Invalid document for entity FISICA') { 
      this.toastHelper.notify(STATUS_HELPER.ERROR, "Documento inválido para Pessoa Física");
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  async getExpoxtList(
    size = 999999,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loadingexport = true;
    const response = await CustomerAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });
    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      this.loadingexport = false;
      return [];
    }
    this.loadingexport = false;
    return response.content.map((usr) => new CustomerModel(usr));
  }

  async setSortAddress(e) {
    const field = e.split(",")[0];
    const direction = e.split(",")[1];

    switch (field) {
      case "zipCode":
      case "number":
        this.addressList = this.sortFieldAddress(field, direction || "asc");
        break;
      case "street":
      case "block":
      case "state":
      case "city":
        this.addressList = this.sortFieldAddressName(field, direction || "asc");
        break;
      default:
        // Lógica para outro campo, se necessário
        break;
    }
  }

  sortFieldAddress(field, direction) {
    return this.addressList.sort((a, b) => {
      if (direction === "desc") {
        return b[field] - a[field];
      } else {
        return a[field] - b[field];
      }
    });
  }

  sortFieldAddressName(field, direction) {
    return this.addressList.sort((a, b) => {
      if (direction === "desc") {
        return b[field].localeCompare(a[field]);
      } else {
        return a[field].localeCompare(b[field]);
      }
    });
  }

  async getCustomerByName(filters) {
    const response = await CustomerAPI.getList({
      size: 999999,
      page: 0,
      sort: "created,asc",
      ...filters,
    });

    if (!response.error) {
      if (response.content && response.content.length > 0) {
        return response.content;
      }
      return [];
    }

    return [];
  }

  async createByCSV(file) {
    this.loading = true;
    const response = await CustomerAPI.createByCSV(file);
    this.loading = false;

    if (response?.error) {
      this.toastHelper.notify(
        STATUS_HELPER.WARNING,
        'Alguns clientes não foram salvos com sucesso. Verifique a lista de erros e tente novamente.',
        10000,
      );
      this.massImportResult = response.error;
    } else {
      this.massImportResult = response
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Clientes salvos com sucesso!');
    }
  }

  async getEligibleParents() {
    this.loading = true;
    const response = await AdministradorAPI.getAllAdministrators({
      size: 999,
      page: 0,
    });

    if (!response.error) {
      this.loading = false;
      const parents = response.content.map((usr) => new AdministratorModel(usr));
      this.parents = parents.map((item) => { return { label: item.name, value: item.uuid } })
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      this.parents = [];
    }
  }
}

export default CustomerStore;

import {setLocale} from "yup";
setLocale({
  mixed: {
    default: "É inválido",
    required: "É um campo obrigatório",
    oneOf: values => `deve ser um dos seguintes valores: ${values}`,
    notOneOf: values => `não pode ser um dos seguintes valores: ${values}`
  },
  string: {
    length: length => `Deve ter exatamente ${length} caracteres`,
    min: min => `deve ter pelo menos ${min} caracteres`,
    max: max => `deve ter no máximo ${max} caracteres`,
    email: "tem o formato de e-mail inválido",
    url: "deve ter um formato de URL válida",
    trim: "não deve conter espaços no início ou no fim.",
    lowercase: "deve estar em minúsculo",
    uppercase: "deve estar em maiúsculo",
  },
  number: {
    min: min => `deve ser no mínimo ${min}`,
    max: max => `deve ser no máximo ${max}`,
    lessThan: less => `deve ser menor que ${less}`,
    moreThan: more => `deve ser maior que ${more}`,
    positive: "deve ser um número positivo",
    negative: "deve ser um número negativo",
    integer: "deve ser um número inteiro"
  },
  date: {
    min: min => `deve ser maior que a data ${min}`,
    max: max => `deve ser menor que a data ${max}`
  },
  array: {
    min: min => `deve ter no mínimo ${min} itens`,
    max: max => `deve ter no máximo ${max} itens`
  }
});

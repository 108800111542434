import BaseAPI, { URLS } from "./BaseAPI";

class PerfilAPI {
  static _exception(_e, message) {
    return { error: message };
  }

  /**Busca lista de autorizações */
  static async getListAuthorities() {
    try {
      const response = await BaseAPI.get(
        `${URLS.AUTHORITY}/search?page=0&size=9999`
      );
      if (response.status === 200) return response.data;
      return { error: "Erro inexperado ao busca listas de autorizações" };
    } catch (e) {
      return this._exception(e, "Falha ao buscar lista de autorizações");
    }
  }

  /**Busca os perfis */
  static async getPerfil(params) {
    try {
      const response = await BaseAPI.get(`${URLS.AUTHORITY_GROUPS}/search`, {
        ...params,
      });

      if (response.status === 200) return response.data;
      return { error: "Erro inexperado ao busca listas de autorizações" };
    } catch (e) {
      return this._exception(e, "Falha ao buscar lista de autorizações");
    }
  }

  /**Busca os perfis */
  static async getUuidPerfil(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.AUTHORITY_GROUPS}/${uuid}`);
      if (response.status === 200) return response.data;
      return { error: "Erro inexperado ao busca listas de autorizações" };
    } catch (e) {
      return this._exception(e, "Falha ao buscar lista de autorizações");
    }
  }

  /**
   * @param  {string} uuid Uuid do usuário que será editado
   * @param {object} data dados do usuário que será editado
   */
  static async createPerfil(data) {
    try {
      const response = await BaseAPI.post(`${URLS.AUTHORITY_GROUPS}`, data);
      if (response.status === 201) return response;
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : "Falha ao criar perfil",
      };
    } catch (e) {
      return {
        error: e?.response?.data?.message
          ? e?.response?.data?.message
          : "Falha ao criar perfil",
      };
    }
  }

  /**
   * Atualizada campos de um merchant.
   * @param  {Object} data Dados a serem atualizados
   */
  static async updatePerfil(data, uuid) {
    try {
      const response = await BaseAPI.put(
        `${URLS.AUTHORITY_GROUPS}/${uuid}`,
        data
      );
      if (response.status === 200) return response;
      return { error: "Erro inesperado ao atualizar perfil" };
    } catch (e) {
      return this._exception(e, "Erro ao atualizar perfil");
    }
  }

  /**
   *
   * @param {string} uuid - uuid do merchant a ser deletado
   */
  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.AUTHORITY_GROUPS}/${uuid}`);
      if (response.status === 204) return response;
      return { error: "Erro inesperado ao excluir perfil" };
    } catch (error) {
      return this._exception(error, "Falha ao excluir perfil");
    }
  }

  /////// USER /////////

  /**Busca todos os admins */
  static async getUser(params) {
    try {
      const response = await BaseAPI.get(URLS.ACCOUNTS, params);
      if (response.status === 200) return response.data;
      return { error: "Erro inexperado ao busca listas de usuarios" };
    } catch (e) {
      return { error: "Falha ao buscar usuarios" };
    }
  }

  /** Busca usuarios por uuid */
  static async getUuidUser(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.ACCOUNTS}/${uuid}?by=uuid`);
      if (response.status === 200) return response.data;
      return { error: "Erro inexperado ao busca usuario" };
    } catch (e) {
      return this._exception(e, "Falha ao buscar usuario");
    }
  }

  /**Busca usuario por nome */
  static async getUserByName(name) {
    try {
      const response = await BaseAPI.get(`${URLS.ACCOUNTS}/${name}?by=name`);
      if (response.status === 200) return response.data;
      return { error: "Erro inexperado ao busca listas de autorizações" };
    } catch (e) {
      return this._exception(e, "Falha ao buscar lista de autorizações");
    }
  }

  /**Atualiza usuario  */
  static async updateUserPerfil(data, uuid) {
    try {
      const response = await BaseAPI.put(
        `${URLS.AUTHORITY_GROUPS}/${uuid}`,
        data
      );
      if (response.status === 200) return response.data;
      return { error: "Erro inexperado ao atualizar usuario" };
    } catch (e) {
      return this._exception(e, "Erro inexperado ao atualizar usuario");
    }
  }

  /**
   * @param  {Object} data Dados do novo usuário administrador
   */
  static async createAdministrator(data) {
    try {
      const response = await BaseAPI.post(URLS.ACCOUNTS, data);
      return response.status === 201
        ? response
        : { error: "Falha ao cadastrar admin" };
    } catch (e) {
      return { error: "Falha ao cadastrar Administrador" };
    }
  }

  /**
   * @param  {string} uuid Uuid do usuário que será editado
   * @param {object} data dados do usuário que será editado
   */
  static async updateAdministrator(data, uuid, user_uuid) {
    try {
      // const response = await BaseAPI.put(`${URLS.ACCOUNTS}/${uuid}`, data);
      const response = await BaseAPI.put(
        `/manager/authority-groups/${uuid}/user/${user_uuid}`
      );
      if (response.status === 200) return response.data;
      return { error: "Falha ao atualizar informações do usuário" };
    } catch (e) {
      return { error: e.message };
    }
  }

  /**
   * Request que lista as variações
   * @param {string} name nome do produto
   */
  static async getResponsible(name) {
    try {
      const url = `${URLS.ACCOUNTS}/search/${name}`;
      const response = await BaseAPI.get(url);
      if (response.status === 200) return response.data;
      return { error: "Erro inesperado ao buscar responsavel" };
    } catch (e) {
      return this._exception(e, "Falha ao buscar responsavel");
    }
  }

  /**
   * @param  {string} uuid Uuid do usuário que será excluído
   */
  static async deleteAdministrator(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.ACCOUNTS}/${uuid}`);
      if (response.status === 200) return response.data;
      return { error: "Não foi possível excluir o administrador" };
    } catch (e) {
      return { error: e.message };
    }
  }
}

export default PerfilAPI;

import BaseModel from './BaseModel';

class AddressModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.uuid = data.uuid;
    this.idAddress = data.idAddress;
    this.street = data.street;
    this.number = data.number;
    this.block = data.block;
    this.zipCode = data.zipCode;
    this.city = data.city;
    this.state = data.state;
    this.country = data.country;
    this.refAddress = data.refAddress;
    this.addressName = data.addressName;
    this.mainAddress = data.mainAddress;
    this.billingAddress = data.billingAddress;
  }
}

export default AddressModel;

import BaseAPI, { URLS } from "./BaseAPI";

class DynamicHomeAPI {
  static async getList(params) {
    try {
      const response = await BaseAPI.get(`${URLS.DYNAMIC_HOME}`, params);
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar a lista de Home Dinamica" };
    } catch (e) {
      return { error: "Falha ao carregar a lista de Home Dinamica" };
    }
  }

  static async save(data) {
    try {
      const response = await BaseAPI.post(URLS.DYNAMIC_HOME, data);
      return response.status === 201 || response.status === 200
        ? response.data
        : { error: "Falha ao salvar Home Dinamica" };
    } catch (e) {
      return { error: "Falha ao salvar Home Dinamica" };
    }
  }

  static async update(uuid, data) {
    try {
      const response = await BaseAPI.put(`${URLS.DYNAMIC_HOME}/${uuid}`, data);
      return response.status === 200
        ? response.data
        : { error: "Falha ao atualizar Home Dinamica" };
    } catch (e) {
      return { error: "Falha ao atualizar Home Dinamica" };
    }
  }

  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.DYNAMIC_HOME}/${uuid}`);
      return response.status === 200
        ? response.data
        : { error: "Falha ao carregar Home Dinamica" };
    } catch (e) {
      return { error: "Falha ao carregar Home Dinamica" };
    }
  }

  static async delete(uuid) {
    try {
      const response = await BaseAPI.delete(`${URLS.DYNAMIC_HOME}/${uuid}`);
      return response.status === 200 || response.status === 204
        ? response.data
        : { error: "Falha ao deletar Home Dinamica" };
    } catch (e) {
      return { error: "Falha ao deletar Home Dinamica" };
    }
  }
}

export default DynamicHomeAPI;

import BaseModel from "./BaseModel";

export default class PickupBranchModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.uuid = data.uuid;
    this.externalId = data.externalId;
    this.created = data.created;
    this.active = data.active;
    this.deleted = data.deleted;
    this.idBranch = data.idBranch;
    this.stockList = data.stockList;
    this.name = data.name;
    this.street = data.street;
    this.number = data.number;
    this.block = data.block;
    this.zipcode = data.zipcode;
    this.formattedZipCode = data.formattedZipCode;
    this.city = data.city;
    this.state = data.state;
    this.document = data.document;
    this.email = data.email;
    this.phone = data.phone;
    this.openingHours = data.openingHours;
    this.saturdayOpeningHours = data.saturdayOpeningHours;
    this.sundayOpeningHours = data.sundayOpeningHours;
    this.holidayOpeningHours = data.holidayOpeningHours;
    this.lat = data.lat;
    this.lng = data.lng;
    this.maxPickupSchedulingDays = data.maxPickupSchedulingDays;
  }
}

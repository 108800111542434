const PropertiesPermissionEnum = Object.freeze({
  POST: {
    pattern: "/manager/properties/classes",
    action: "POST",
  },
  PUT: {
    pattern: "/manager/properties/classes/**",
    action: "PUT",
  },
  DELETE: {
    pattern: "/manager/properties/property/**",
    action: "DELETE",
  },
  GET: {
    pattern: "/manager/properties/classes/**",
    action: "GET",
  },
  GET_UUID: {
    pattern: "/manager/properties/classes/**",
    action: "GET",
  },
});

export default PropertiesPermissionEnum;
